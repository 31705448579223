import React, { useEffect } from 'react';
import { useState } from 'react';
// import CryptoWallet from "../UserDirectory/balance/CryptoWallet";
// import FiatWallet from "../UserDirectory/balance/FiatWallet";
import LiquidityManagement from './LiquidityManagement';
import LiquidityProvider from './LiquidityProvider';

import { WalletActions } from '../../../redux/features/wallet/walletActions';
import { useDispatch} from 'react-redux';
import {
  startLoading,
  finishLoading,
} from '../../../redux/features/loading/loadingSlice';
import { toast } from 'react-toastify';
import { TransactionFeeService } from '../../../services/transaction-fee.service';
import { BankingActions } from '../../../redux/features/banking/bankingActions';
import { SwapActions } from '../../../redux/features/swap/swapActions';

const { useGetLiquidityAndAssetsQuery } =
  WalletActions.getLiquidityAndAssetsRtk;

export const Liquidity = () => {
  const { data, isLoading} = useGetLiquidityAndAssetsQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  );

  const [liquidityAssets, setLiquidityAssets] = useState([]);
  const [fiatBalances, setFiatBalances] = useState([]);
  const [swapBalance, setSWapBalance] = useState([]);
  const [cmcPrices, setCmcPrices] = useState([]); // State to hold cmcPrices

  const dispatch = useDispatch();

  const getLiquidityAssets = async () => {
    try {
      if (!data) {
        dispatch(startLoading());
      } else {
        const { adminCurrencyInfo, adminWalletBalance, cmcPrices } =
          data.response.data;
          setCmcPrices(cmcPrices); // Save cmcPrices to state
        if (
          adminWalletBalance &&
          adminWalletBalance.length > 0 &&
          cmcPrices &&
          cmcPrices.length > 0 &&
          adminCurrencyInfo &&
          adminCurrencyInfo.length > 0
        ) {
          console.log('adminWalletBalance::', adminWalletBalance);
          const data = addUsdValueInWallets(
            adminWalletBalance,
            cmcPrices,
            adminCurrencyInfo
          );
          const formattedUserData = formatBalances(data);
          setLiquidityAssets(formattedUserData);
        } else {
          setLiquidityAssets([]);
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  const addUsdValueInWallets = (
    adminWalletBalance,
    cmcPrices,
    adminCurrencyInfo
  ) => {
    const liquidities = [];
    for (const wallet of adminWalletBalance) {
      let walletCoin = wallet.coin.toLowerCase();
      walletCoin = walletCoin.split('(')[0];
      if (walletCoin === 'mat-usdt') walletCoin = 'usdt';
      else if (walletCoin === 'mat-usdc') walletCoin = 'usdc';
      let myWallet = { ...wallet };

      const currency = adminCurrencyInfo.find((c) => c.coin === wallet.coin);
      if (currency) {
        myWallet.fee = TransactionFeeService.getFeeAmountAfterPrecision(
          currency.coin,
          currency.totalFee
        );
      }

      const cmc = cmcPrices.find(
        (cmc) => cmc.cmcId.toLowerCase() === walletCoin
      );
      console.log('cmc::', cmc);
      if (cmc) myWallet.usdValue = wallet.balance * cmc.usdPrice || 0.0;
      if (cmc) myWallet.pendingUSDValue = TransactionFeeService.getFeeAmountAfterPrecision(
        myWallet.coin, myWallet.pendingBalance) * cmc.usdPrice || 0.0;
      if (cmc && wallet.gasFeeBalance) myWallet.gasUSDValue = wallet.gasFeeBalance * cmc.usdPrice || 0.0;
      liquidities.push(myWallet);
    }
    console.log('liquidities', liquidities);
    return liquidities;
  };

  const formatBalances = (data) => {
    console.log('data::', data);
    return data.map((obj, index) => {
      return {
        number: index + 1,
        totalBalance: obj.balance,
        pendingBalance: TransactionFeeService.getFeeAmountAfterPrecision(obj.coin, obj.pendingBalance),
        coin: obj.coin,
        coinUrl: obj.iconUrl,
        hot: obj.hot,
        deposit: obj.deposit,
        fee: obj.fee,
        usdValue: obj.usdValue,
        pendingUSDValue: obj.pendingUSDValue,
        gasUSDValue: obj.gasUSDValue,
        network: obj.network,
        gasFeeBalance: obj.gasFeeBalance,
        centralAddress: obj.centralAddress,
        centralGasAddress: obj.centralGasAddress,
        pendingTx: obj.PendingTx,
      };
    });
  };

  const formatBalanceForCalculation = (balance) => {
    const numericBalance = parseFloat(balance);
    if (isNaN(numericBalance)) return 0; 
    return numericBalance / 100;
  };

  const getFiatBalances = async () => {
    try {
      dispatch(startLoading());
      let res = await dispatch(BankingActions.getFiatBalances()).unwrap();
      console.log({ response: res });
      if (res && res.data) {     
        const val = cmcPrices.find(cmc => cmc.cmcId.toLowerCase() === 'eur')?.usdPrice;
        const formattedFiatBalances = res.data.map((fiat) => ({
          ...fiat, 
          balance: formatBalanceForCalculation(fiat.balance),
          usdValue: val ? formatBalanceForCalculation(fiat.balance) * val : 0,
          pendingBalance: 0,
          pendingUSDValue: 0
        }));

        setFiatBalances(formattedFiatBalances);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const checkType = (res) => {
    if (Array.isArray(res)) {
      return 'array';
    } else if (typeof res === 'object' && res !== null) {
      return 'object';
    }
  };

  const getSwapBalances = async () => {
    let data = {
      apiAccessKey: 'DASHBOARD',
    };
    try {
      dispatch(startLoading());
      let res = await dispatch(SwapActions.getSwapBalance(data)).unwrap();
      let resultData = [];
      if (res) {
        let resType = checkType(res);
        if (resType === 'object') {
          if (Object.keys(res).length === 0) {
            resultData = [];
          } else {
            resultData.push(res);
          }
        } else {
          resultData = res;
        }      // Add USDPrice calculation
        const updatedSwapBalances = resultData.map((swap) => {
          const swapCoin = swap.currency?.toLowerCase();
          // Check if the swapCoin is "usd"
          if (swapCoin === 'usd') {
            return {
              ...swap,
              usdValue: swap.balance * 1, 
            };
          }

          const cmc = cmcPrices.find(
            (cmc) => cmc.cmcId.toLowerCase() === swapCoin
          );
  
          return {
            ...swap,
            usdValue: cmc ? swap.balance * cmc.usdPrice : 0,
          };
        });
  
        setSWapBalance(updatedSwapBalances);
      } else {
        setSWapBalance([]);
      }
      
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  useEffect(() => {
    getLiquidityAssets();
  }, [data, isLoading]);

  useEffect(() => {
    if (cmcPrices !== null) {
      getFiatBalances();
      getSwapBalances();
    }
  }, [cmcPrices]);

  console.log('liquidityAssets::', liquidityAssets);

  return (
    <>
      <div>
       <LiquidityManagement cryptodata={liquidityAssets} narvidata={fiatBalances}  />
       <LiquidityProvider cryptodata={liquidityAssets} narvidata={fiatBalances} swapdata={swapBalance}  />
      </div>
    </>
  );
};
