import FiatItem from "./FiatItem";
import NetworkCard from "./NetworkCard";
import styles from "./style.module.scss"; 
import InfoCard from "../../../Components/Card/InfoCard";
import { TransactionFeeService } from "../../../services/transaction-fee.service";
import { CONSTANTS } from "../../../constants";

const LiquidityProvider = ({ cryptodata, narvidata, swapdata}) => {

	const { LIQUIDITY_MANAGEMENT_CONSTANTS } = CONSTANTS;
    const swapdataLength = swapdata.length;

    // Group data by network
    const networkData = cryptodata.reduce((acc, item) => {
        const network = item.network;
        acc[network] = acc[network] || [];
        acc[network].push(item);
        return acc;
    }, {});

    // Calculate the total PendingTx for all networks
    const totalPendingTx = cryptodata.reduce((sum, item) => sum + (item.pendingTx || 0), 0);

    const totalSwapBalance = swapdata.reduce((sum, item) => sum + (item.usdValue || 0), 0);
    
    return(
    <div>
    {/* Header Section */}
    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: 32 }}>
        <div style={{ fontSize: 40, fontWeight: 700 }}>{LIQUIDITY_MANAGEMENT_CONSTANTS.PROVIDER_NETWORK}</div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', width: 100 }}>
                <div style={{ fontSize: 12 }}>{LIQUIDITY_MANAGEMENT_CONSTANTS.PENDING_TX}</div>
                <div style={{ fontWeight: 700, fontSize: 24 }}>{totalPendingTx}</div>
            </div>
        </div>
    </div>
    <FiatItem fiatItems={narvidata} />   
     {/* NetworkCard Items */}
     {Object.entries(networkData).map(([network, networkItems]) => (
            <NetworkCard  key={network} networkItems={networkItems}/>
      ))}

    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: 32, paddingBottom:20, paddingTop:80 }}>
            <div style={{ fontSize: 40, fontWeight: 700 }}>Liquidity Providers</div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', 
                    borderRadius:12, borderColor:'#E7E9ED', borderStyle: 'solid', borderWidth:1, paddingTop:11,
                    paddingBottom:11, paddingRight: 16, paddingLeft:16, height:40
                 }}>
                    CSV
                </div>
            </div>
    </div> 
    <div style = {{display: 'flex', flexDirection:'row', justifyContent:'space-between', marginTop:24}}>
            <div>
                <div style={{fontSize:32, fontWeight:600}}>B2C2</div>
                <div style={{ fontSize:12, paddingRight:12, paddingBottom:12, paddingTop:16}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.CURRENCY_TOKEN}: {swapdataLength}</div>
            </div>
            
        <div style={{gap:20}}>
            <div className={styles.container}>
                <div className={`${styles.labelInABox} ${styles.card}`}>
                    <InfoCard key="key1" title="Total, $" 
                        detail={Number(totalSwapBalance || 0).toLocaleString()}
                    />
                </div>
                <div className={`${styles.labelInABox} ${styles.card}`}>
                    <InfoCard key="key1" title="Swap Daily Liabilities" 
                        detail="-"
                    />
                </div>
                <div className={`${styles.labelInABox} ${styles.card}`}>
                    <InfoCard key="key1" title="Total Available Balance" 
                        detail="-"
                    />
                </div>
                
                
            </div>
            { Object.keys(swapdata).length > 0 && (
                <div className={styles.container} style={{paddingTop: 20, borderBottomWidth:1, borderBottomColor:'#64717C', borderBottomStyle: 'solid'}}>
                     <div style={{width:180, paddingTop:8, paddingBottom:8}}>
                    </div>
                    <div style={{ width: 200, paddingTop:8, paddingBottom:8, textAlign:'right'}}>
                        {LIQUIDITY_MANAGEMENT_CONSTANTS.BALANCE}
                    </div>
                </div>
            ) }
           { Object.entries(swapdata).map(([network, swapItem]) => (
                <div className={styles.container} style={{paddingTop: 20, paddingBottom: 20, fontWeight: 600}}>
                     <img
                                alt={swapItem.currency}
                                src={TransactionFeeService.getImageByTokenName(swapItem.currency)}
                                style={{ width: '40px', height: 'auto' }}
                    />
                    <div style={{width:120, paddingTop:8, paddingBottom:8, textAlign:"left"}}>
                        {swapItem.currency}
                    </div>
                    <div style={{ width: 200, paddingTop:8, paddingBottom:8, textAlign:'right'}}>
                        {Number(swapItem.balance || 0).toLocaleString()}
                    </div>
            </div>
             ))}
            
        </div>
     </div>  

</div>
);
};

export default LiquidityProvider;