export const REWARDS_DATA = [
    {
      key: '1',
      riselyId: '12345',
      name: 'John Brown',
      referralCode: 'REF123',
      signedUp: '2023-01-01',
      verified: 'Yes',
      totalRewardEarned: '100 USD',
      accountVerificationRewards: '50 USD',
      transactionRewards: '50 USD',
      redeemedRewards: '80 USD',
      rewardBalance: '20 USD',
      updated: '2023-10-01',
    },
    {
      key: '2',
      riselyId: '67890',
      name: 'Jim Green',
      referralCode: 'REF456',
      signedUp: '2023-02-15',
      verified: 'No',
      totalRewardEarned: '200 USD',
      accountVerificationRewards: '100 USD',
      transactionRewards: '100 USD',
      redeemedRewards: '150 USD',
      rewardBalance: '50 USD',
      updated: '2023-11-05',
    },
];
