import { CONSTANTS } from '.';

export const checkPermissionFn = (routes, currentRoute, role) => {
  console.log({ routes, currentRoute, role });
  const { PERMISSION_TYPE } = CONSTANTS;
  if (routes && routes.length > 0) {
    const foundPermission = routes.find(
      (obj) => currentRoute.indexOf(obj.route) != -1
    );
    return (
      foundPermission?.permissionType === PERMISSION_TYPE.READWRITE ||
      role === 'Master Admin'
    );
  }
};

// export function formatDocumentName(obj) {
//   let documentName = '' ; 
//   console.log('input', obj?.docName);
//   const value = obj?.docName.toString();
//   if (value !== undefined) {
//     const words = value.split('_');
//     console.log('words',words);
//     const newWordsArr = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//     console.log('newWordsArr',newWordsArr);
//     for (let index = 0; index < newWordsArr.length; index++) {
//       documentName += newWordsArr[index] + ' '
//     }
//     console.log('documentName',documentName);
//     return "adsadsa"
//   }

//   return value;
// }


export function formatDocumentName(obj) {
  const value = obj?.docName;

  if (typeof value === 'string') {
    const documentName = value
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    console.log('Formatted Document Name:', documentName);
    return documentName;
  }

  console.log('Input is not a valid string:', value);
  return value; // Return undefined or original value if not a valid string
}

export const formatNumberLiquidityMngmt = (value, isCrypto) => {
  if (isCrypto) {
    return `${Number(value).toLocaleString(undefined, { minimumFractionDigits: 8, maximumFractionDigits: 8 })}`;
  } else {
    return `${Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
};

export const formatNumberWithoutDecimals = (value) => {
  return `${Number(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
};
