import React, { useEffect, useState } from 'react';
import { Table, Pagination, Modal, Button, Space } from 'antd';
import ButtonCommon from '../../../../Components/Buttoncommon/ButtonCommon';
import TableFilterTittle from '../../../../Components/TableFilterTittle/TableFilterTittle';
import { useDispatch, useSelector } from 'react-redux';
import { ComplianceActions } from '../../../../redux/features/compliance/complianceAtion';
import {
  finishLoading,
  startLoading,
} from '../../../../redux/features/loading/loadingSlice';
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
  iconColor,
  iconSize,
} from '../../../../utils/common.utils';
import PermissionCheck from '../../../../Components/PermissionCheck/PermissionCheck';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TransactionScreeningFilters from '../TransactionScreeningFilters';
import { CloseOutlined, CheckOutlined, EyeOutlined } from '@ant-design/icons';
import ComplianceFilterTittle from '../../../../Components/TableFilterTittle/ComplianceTableFilterTitle';
import CopyToClipboard from '../../../../Components/CopyToClipboard/CopyToClipboard';
import { WalletActions } from '../../../../redux/features/wallet/walletActions';
import { CONSTANTS } from '../../../../constants';
import { KycActions } from '../../../../redux/features/kyc/kycActions';
import {
  formatCryptoTypeId,
  formatCryptoTypeParam,
} from '../../../../services/transaction.service';
import { setTransactionDetail } from '../../../../redux/features/compliance/complianceSlice';

const CryptoScreening = ({ getKytTxn, data, kytDetails }) => {
  const { selectedComplianceTab } = useSelector((state) => state?.compliance);
  console.log(selectedComplianceTab, 'pendingCase');
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({
    typeId: '',
    status: '',
    amount: '',
    coin: '',
    clientId: '',
    type: '',
    withdrawId: '',
  });
  const [filterData, setFilterData] = useState({});
  const [clickedStatus, setClickedStatus] = useState('');
  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    try {
      const data = {
        offset: (page - 1) * pageSize,
        size: pageSize,
        txnStatus: filterData.txnStatus,
        coinName: '',
        type: 'CRYPTO',
        startDate:filterData.startDate,
        endDate:filterData.endDate
      };
      getKytTxn(data);
    } catch (error) {
      console.log(error);
    }
  };

  const showModal = (record, status) => {
    console.log(record, 'recordrecord');
    setUpdateStatus({
      typeId: record.typeId,
      status: status === 'approve' ? 'COMPLETED' : 'REJECTED',
      clientId: record.clientId,
      txnType: record.txnType,
      amount: record.amount,
      // coin: record.coinName,
      coin: record.coinSymbol,
      withdrawId: record.withdrawId,
      toAddres: record.toAddress,
      fromAddress: record.fromAddress,
    });
    setIsModalOpen(true);
    setClickedStatus(status);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const sendFormattedTxnType = (type) => {
    switch (type) {
      case 'CRYPTO_WITHDRAW':
        return 1;
      case 'CRYPTO_DEPOSIT':
        return 'suspicious-trx';
    }
  };
  const handleOk = async () => {
    console.log(updateStatus, 'updateStatus');
    let requestPayload = {};
    try {
      requestPayload = {
        coin: updateStatus.coin.toLowerCase(),
        status: updateStatus.status,
        typeId: updateStatus.typeId,
        amount: updateStatus.amount,
        type: sendFormattedTxnType(updateStatus.txnType),
        trxType: updateStatus.txnType,
        clientId: updateStatus.clientId,
      };

      await dispatch(startLoading());
      const res = await dispatch(
        ComplianceActions.updateToKytStatus(requestPayload)
      ).unwrap();
      const data = {
        offset: 0,
        size: pageSize,
        txnStatus: filterData?.txnStatus,
        coinName: '',
        type: '',
      };
      await getKytTxn(data);
    } catch (error) {
    } finally {
      setIsModalOpen(false);
      await dispatch(finishLoading());
    }
  };
  const handleViewTransactionsDetails = async (record) => {
    if (!record.sumsubResponse) {
      return toast.error('No data found');
    } else if (
      record.sumsubResponse === '[object Object]' ||
      record.sumsubResponse === '{}'
    ) {
      return toast.error('No data found');
    }

    let res = await dispatch(
      WalletActions.getTransactionsDetailsById({
        // coin: record?.coinName,
        coin: record?.coinSymbol,
        clientId: record?.clientId,
        type: record.txnType === 'CRYPTO_DEPOSIT' ? 0 : 1,
        apiKey: CONSTANTS.ENV.TRANSACTION_KEY,
        typeId: formatCryptoTypeId(record?.typeId),
      })
    ).unwrap();

    let newRes = await dispatch(
      KycActions.getKycTxnDetails({
        typeId: record?.typeId,
      })
    ).unwrap();

    navigate('/transactions-main-detail', {
      state: {
        sumsubResponse: newRes,
        status: record.txnStatus,
        data: res,
        type: record.type,
        userId: record.userId,
        txnId: record.typeId,
      },
    });
  };

  const showActionsPerType = (record) => {
    let type = record?.type;
    let status = record?.txnStatus;

    if (type) {
      if (type.toUpperCase() === 'CRYPTO' && status === 'PENDING') {
        return (
          <Space>
            {/* <PermissionCheck>
              <ButtonCommon
                className='filter-btn'
                reject='approve'
                width={45}
                onClick={() => showModal(record, 'approve')}
              >
                <CheckOutlined />
              </ButtonCommon>
              &nbsp; &nbsp;
              <ButtonCommon
                className='filter-btn'
                reject='reject'
                onClick={() => showModal(record, 'reject')}
              >
                <CloseOutlined />
              </ButtonCommon>
            </PermissionCheck> */}

            <EyeOutlined
              style={{ color: iconColor, fontSize: iconSize }}
              onClick={() => {
                handleViewTransactionsDetails(record);
                // dispatch(setTransactionDetail(record));
              }}
            />
          </Space>
        );
      }

      return (
        <EyeOutlined
          style={{ color: iconColor, fontSize: '20px' }}
          onClick={() => handleViewTransactionsDetails(record)}
        />
      );
    }
    return (
      <Space>
        <PermissionCheck>
          <ButtonCommon
            type='primary'
            text='Approve'
            onClick={() => showModal(record)}
          />
        </PermissionCheck>
        <Button
          type='primary'
          onClick={() => handleViewTransactionsDetails(record)}
        >
          More Details
        </Button>
      </Space>
    );
  };

  const formatTxnTypes = (type) => {
    switch (type) {
      case 'FIAT_WITHDRAW':
        return 'WITHDRAW';
      case 'FIAT_DEPOSIT':
        return 'DEPOSIT';
      case 'CRYPTO_WITHDRAW':
        return 'WITHDRAW';
      case 'CRYPTO_DEPOSIT':
        return 'DEPOSIT';
      default:
        return '-';
    }
  };

  const columns = [
    {
      title: 'Transaction Id',
      dataIndex: 'txnId',
      key: 'txnId',
      render: (_, record) => {
        return (
          <>
            {record.txnId ? (
              <>
                <span style={{ cursor: 'pointer' }}>
                  {handleHideId(record.txnId)}
                </span>

                <CopyToClipboard userId={record.txnId} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      key: 'clientId',
      render: (_, record) => (
        <>
          {record.clientId ? (
            <>
              <span
              >
                {handleHideId(record.clientId)}
              </span>

              <CopyToClipboard userId={record.clientId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Coin Name',
      dataIndex: 'coinName',
      key: 'coinName',
      render: (_, record) => checkEmptyString(record.coinName.toUpperCase()),
    },
    {
      title: 'Coin Network',
      dataIndex: 'coinNetwork',
      key: 'coinNetwork',
      render: (_, record) =>
        checkEmptyString(
          record.coinNetwork !== record.coinSymbol ? record.coinNetwork : ''
        ),
    },
    {
      title: 'Txn Type',
      dataIndex: 'txnType',
      key: 'txnType',
      render: (_, record) => checkEmptyString(formatTxnTypes(record.txnType)),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => checkEmptyString(record.type),
    },

    {
      title: 'Amount ',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => checkEmptyString(record.amount),
    },
    {
      title: 'Status ',
      dataIndex: 'txnStatus',
      key: 'txnStatus',
      render: (_, record) => checkEmptyString(record.txnStatus),
    },
    {
      title: 'From',
      dataIndex: 'fromAddress',
      key: 'fromAddress',
      render: (_, record) => {
        return (
          <>
            {record?.fromAddress ? (
              <>
                <span style={{ cursor: 'pointer' }}>
                  {handleHideId(record?.fromAddress)}
                </span>

                <CopyToClipboard userId={record?.fromAddress} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'To',
      dataIndex: 'toAddress',
      key: 'toAddress',
      render: (_, record) => {
        return (
          <>
            {record?.toAddress ? (
              <>
                <span style={{ cursor: 'pointer' }}>
                  {handleHideId(record?.toAddress)}
                </span>

                <CopyToClipboard userId={record?.toAddress} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => formatDateTime(record.updatedAt),
    },

    {
      title: 'Action',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return <>{showActionsPerType(record)}</>;
      },
    },
  ];

  return (
    <>
      <div className='table-design'>
        <div className='title-filter'>
          <ComplianceFilterTittle title='Crypto Transactions' />
          <TransactionScreeningFilters
            setFilterData={setFilterData}
            pageSize={pageSize}
            getKytTxn={getKytTxn}
            setPage={setPage}
            type={'CRYPTO'}
          />
        </div>

        <div className='custom-table'>
          <Table
            dataSource={kytDetails.list}
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      <div className='pagination_block' style={{ marginTop: '20px' }}>
        <span className='records'>
          Total Records: {kytDetails.totalCount > 0 ? kytDetails.totalCount : 0}
        </span>
        {kytDetails.totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={kytDetails.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={page}
          />
        )}
      </div>

      <Modal
        className='logout custom-modal'
        width={545}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
      >
        <div className='modal-inner'>
          <h3>Are you sure want to {clickedStatus}?</h3>
          <p></p>
          <div className='logout_btn'>
            <ButtonCommon
              text='No'
              width={176}
              height={46}
              transBtn='transBtn'
              custmcls='cursorpointer'
              onClick={handleCancel}
            />
            <ButtonCommon
              type='primary'
              text='Yes'
              width={176}
              onClick={handleOk}
              customBlueBtn='customBlueBtn'
              height={46}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CryptoScreening;
