import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { goBackInitialPage } from '../../../redux/features/dashboard/dashboardSlice';
import {
  setSelectedComplianceTab,
  setSelectedSanctionTab,
  setSelectedVerificationTab,
} from '../../../redux/features/compliance/complianceSlice';
import { setSelecteClientSupportTab } from '../../../redux/features/clientSupport/clientSupportSlice';
import { setSelectedFiatTab } from '../../../redux/features/nonReference/nonReferenceSlice';
import { SIDEBAR_CONSTANTS } from '../../../utils/constants';
const BreadcrumbHeader = () => {
  const selectedTab = useSelector((state) => state.user.selectedTab);
  const { selectedComplianceTab, selectedKey } = useSelector(
    (state) => state?.compliance
  );
  const selectedClientSupportTab = useSelector(
    (state) => state?.clietSupport?.selectedClientSupportTab
  );

  const selectedSanctionTab = useSelector(
    (state) => state?.compliance?.selectedSanctionTab
  );
  const sanctionVerificationTab = useSelector(
    (state) => state?.compliance?.selectedVerificationTab
  );

  const selectedSanctionType = useSelector(
    (state) => state.compliance?.selectedSanctionType
  );

  const selectedFiatTab = useSelector(
    (state) => state?.nonReferences?.selectedFiatTab
  );

  const id = useSelector((state) => state.user.data?.user?.userId);
  const location = useLocation();
  const dispatch = useDispatch();
  const [pageHeader, setPageHeader] = useState('');

  useEffect(() => {
    changePageHeader();
  }, [location.pathname, selectedTab]);
  useEffect(() => {
    changePageHeader();
  }, [location.pathname, selectedComplianceTab, selectedKey]);

  useEffect(() => {
    changePageHeader();
  }, [
    location.pathname,
    selectedClientSupportTab,
    selectedSanctionTab,
    selectedSanctionType,
    sanctionVerificationTab,
    selectedFiatTab,
  ]);

  const goBack = () => {
    dispatch(goBackInitialPage());
  };

  const goBackSanction = (value) => {
    goBack();
    dispatch(setSelectedSanctionTab(value));
  };

  const handleSelectedTab = () => {
    if (
      location.pathname !== 'client-support-communication' &&
      location.pathname !== '/client-support-communication-ticket-info'
    ) {
      dispatch(setSelecteClientSupportTab('Pending'));
    }
  };
  const handleComplinaceVerificationTab = () => {
    if (
      location.pathname !== '/sanction-report-verifications-screening' &&
      location.pathname !== '/sanction-report-kyc'
    ) {
      dispatch(setSelectedVerificationTab('Rejected'));
    }
  };
  const handleSelectedFiatTab = () => {
    if (location.pathname !== '/fiat-transaction') {
      dispatch(setSelectedFiatTab('Fiat Transactions'));
    }
  };
  const selectComplienSupport = () => {
    if (
      location.pathname !== '/transactions-main' &&
      location.pathname !== '/transactions-main-detail'
    ) {
      dispatch(setSelectedComplianceTab('All'));
    }
  };
  const createBreadcrumb = (items, separator = '>') => (
    <div className='custom-breadCrumb'>
      <Breadcrumb separator={separator} items={items} />
    </div>
  );

  useEffect(() => {
    if (
      location.pathname !== '/sanction-report' &&
      location.pathname !== '/sanction-report-kyc' &&
      location.pathname !== '/sanction-report-verifications-screening'
    ) {
      dispatch(setSelectedSanctionTab('Individual'));
    }
  }, [location.pathname]);

  const breadcrumbMap = {
    '/dashboard': createBreadcrumb([{ title: 'Dashboard' }]),
    '/user-directory': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Client Management' },
      { title: 'Client Directory' },
    ]),

    '/user-directory-view':
      selectedTab === 'Personal Information'
        ? createBreadcrumb([
          { title: <Link to='dashboard'>Dashboard</Link> },
          { title: 'Client Management' },
          { title: 'Client Directory' },
          { title: id },
        ])
        : selectedTab === 'KYC Verification'
          ? createBreadcrumb([
            {
              title: <Link to='/dashboard'>Dashboard</Link>,
            },
            {
              title: 'Client Management',
            },
            {
              title: 'Client Directory',
            },

            {
              title: id,
            },
          ])
          : selectedTab == 'Risk Management'
            ? createBreadcrumb([
              {
                title: <Link to='/dashboard'>Dashboard</Link>,
              },
              {
                title: 'Client Management',
              },
              {
                title: 'Client Directory',
              },

              {
                title: id,
              },
            ])
            : selectedTab === 'Cards Information'
              ? createBreadcrumb([
                {
                  title: <Link to='/dashboard'>Dashboard</Link>,
                },
                {
                  title: 'Client Management',
                },
                {
                  title: 'Client Directory',
                },
                {
                  title: id,
                },
                {
                  title: 'Cards Information',
                },
              ])
              : selectedTab === 'Accounts'
                ? createBreadcrumb([
                  {
                    title: <Link to='/dashboard'>Dashboard</Link>,
                  },
                  {
                    title: 'Client Management',
                  },
                  {
                    title: 'Client Directory',
                  },
                  {
                    title: id,
                  },

                  {
                    title: 'Accounts',
                  },
                ])
                : selectedTab === 'Transactions'
                  ? createBreadcrumb([
                    {
                      title: <Link to='/dashboard'>Dashboard</Link>,
                    },
                    {
                      title: 'Client Management',
                    },
                    {
                      title: 'Client Directory',
                    },
                    {
                      title: id,
                    },

                    {
                      title: 'Transactions',
                    },
                  ])
                  : selectedTab === 'Rewards'
                    ? createBreadcrumb([
                      {
                        title: <Link to='/dashboard'>Dashboard</Link>,
                      },
                      {
                        title: 'Client Management',
                      },
                      {
                        title: 'Client Directory',
                      },
                      {
                        title: id,
                      },

                      {
                        title: 'Rewards',
                      },
                    ])
                    : null,
    '/user-verification': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Client Management' },
      {
        title: (
          <span onClick={() => goBack()} style={{ cursor: 'pointer' }}>
            Client Verification
          </span>
        ),
      },
    ]),

    ///

    '/user-directory-view/transactions-main-detail': createBreadcrumb([
      {
        title: <Link to='/dashboard'>Dashboard</Link>,
      },
      {
        title: 'Client Management',
      },
      {
        title: <Link to={'/user-directory-view'}>Client Directory</Link>,
      },
      {
        title: id,
      },

      {
        title: 'Transactions',
      },
      {
        title: location.state?.txnId,
      },
    ]),
    '/sanction-report': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Compliance' },
      { title: 'Verifications-Screening' },
      { title: selectedSanctionTab },
    ]),

    '/sanction-report-verifications-screening': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Compliance' },
      { title: 'Verifications-Screening' },
      { title: <Link to={'/sanction-report'}>{selectedSanctionTab}</Link> },
      { title: sanctionVerificationTab },
    ]),
    '/sanction-report-kyc': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Compliance' },
      {
        title: 'Verifications-Screening',
      },
      { title: <Link to={'/sanction-report'}>{selectedSanctionTab}</Link> },
      {
        title: (
          <Link to={'/sanction-report-verifications-screening'}>
            {sanctionVerificationTab}
          </Link>
        ),
      },
      { title: sanctionVerificationTab && ' Kyc' },
    ]),

    ///
    '/setting': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
    ]),
    '/logs': createBreadcrumb([
      {
        title: <Link to='dashboard'>Dashboard</Link>,
      },
      {
        title: 'Activity',
      },
    ]),
    '/fiat-transaction': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      // { title: 'Fiat Transactions' },
      { title: selectedFiatTab },
    ]),
    '/fiat-transaction/transactions-main-detail': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      { title: <Link to={'/fiat-transaction'}>Fiat Transactions </Link> },
      { title: location.state?.txnId },
    ]),
    '/pending-transaction': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Transaction Summary' },
      { title: 'Pending Transactions' },
    ]),
    '/crypto-transaction': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      { title: 'Crypto Transactions' },
    ]),
    '/crypto-transaction/transactions-main-detail': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      { title: <Link to={'/crypto-transaction'}>Crypto Transactions</Link> },
      { title: location.state?.txnId },
    ]),
    '/swap-transaction': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      { title: 'Swap Transactions' },
    ]),
    '/swap-transaction/transactions-main-detail': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      { title: <Link to={'/swap-transaction'}>Swap Transactions</Link> },
      { title: location.state?.txnId },
    ]),
    '/content': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Marketing' },
      { title: 'Content' },
    ]),
    '/content-update': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Marketing' },
      { title: <Link to={'/content'}>Content</Link> },
      { title: 'Update-Content' },
    ]),
    '/client-support-communication': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: <Link to='/client-support-communication'>Client Support</Link> },
      { title: selectedClientSupportTab },
    ]),
    '/reward': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: <Link to='/reward'>{SIDEBAR_CONSTANTS.REWARDS}</Link> },
    ]),
    '/transactions-main': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: <Link to='/transactions-main'>Compliance</Link> },
      { title: <Link to='/transactions-main'>Transactions-screening</Link> },
      { title: selectedComplianceTab },
    ]),
    '/transactions-main-detail': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Compliance' },
      { title: <Link to={'/transactions-main'}>{selectedComplianceTab}</Link> },
      { title: location?.state?.sumsubResponse?.id },
    ]),
    '/client-support-communication-ticket-info': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: <Link to='/client-support-communication'>Client Support</Link> },
      {
        title: (
          <Link to='/client-support-communication'>
            {selectedClientSupportTab}
          </Link>
        ),
      },
      { title: `Ticket #${location.state?.record?.ticketId}` },
    ]),
    '/role-management': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: 'Role Management' },
    ]),
    '/role-management/set-permission': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: <Link to={'/role-management'}>Role Management</Link> },
      { title: 'Create Role' },
    ]),
    '/role-management/update-permission': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: <Link to={'/role-management'}>Role Management</Link> },
      { title: 'Update Role' },
    ]),
    '/activity': 'Activity',
    '/banner': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Marketing' },
      { title: 'Banner' },
    ]),
    '/rewards': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'System Settings' },
      { title: 'Rewards' },
    ]),
    '/user-management': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: 'User Management' },
    ]),
    '/transaction-limits': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: 'Fee Settings' },
    ]),

    '/all-transaction': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      { title: 'All Transactions' },
    ]),
    '/all-transaction/transactions-main-detail': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Reports' },
      { title: <Link to={'/all-transaction'}>All Transactions</Link> },
      { title: location.state?.txnId },
    ]),

    '/kms-management': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: 'KMS Management' },
    ]),
    '/referral-program': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: 'Referral Program' },
    ]),
    '/liquidity-assets': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Liquidity & Assets' }
    ]),
    '/trade-management': createBreadcrumb([
      { title: <Link to='dashboard'>Dashboard</Link> },
      { title: 'Setting' },
      { title: 'Trade Management' },
    ]),
  };

  const changePageHeader = () => {
    handleSelectedTab();
    selectComplienSupport();
    handleComplinaceVerificationTab();
    handleSelectedFiatTab();
    const currentPath = location.pathname;
    setPageHeader(breadcrumbMap[currentPath]);
  };

  return <>{pageHeader}</>;
};

export default BreadcrumbHeader;
