import React, { useState } from 'react';
import { REWARDS_COLUMNS, REWARDS_CONSTANTS } from '../../utils/constants';
import { Button, DatePicker, Input, Pagination, Select, Switch, Table } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './rewards.module.scss';
import { REWARDS_DATA } from '../../utils/mockData';

const defaultCheckedList = REWARDS_COLUMNS(styles).map((item) => item.key);

const Rewards = () => {
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const newColumns = REWARDS_COLUMNS(styles).map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  return (
    <div className={styles.rewardsContainer}>
      <div className={styles.rewardsHeader}>
        <div className={styles.rewardsHeading}>{REWARDS_CONSTANTS.REWARDS}</div>
        <div className={styles.rewardsToggleText}>
          <Switch defaultChecked size="small" />
          <div className={styles.toggleText}>{REWARDS_CONSTANTS.REWARDS_ACTIVE}</div>
        </div>
      </div>
      <div className={styles.rewardsSortFilterTableContainer}>
        <div className={styles.searchBar}>
          <Input
            prefix={<SearchOutlined />}
            placeholder={REWARDS_CONSTANTS.SEARCH_PLACEHOLDER}
          />
        </div>
        <div className={styles.sortFiltersContainer}>
          <Select className={styles.statusFilter} placeholder={REWARDS_CONSTANTS.STATUS} />
          <DatePicker className={styles.datePicker} placeholder={REWARDS_CONSTANTS.UPDATED_FROM} />
          <DatePicker className={styles.datePicker} placeholder={REWARDS_CONSTANTS.UPDATED_TO} />
          <Select className={styles.statusSort} placeholder={REWARDS_CONSTANTS.SORT_BY_STATUS} />
          <Button className={styles.filterBtn}>{REWARDS_CONSTANTS.FILTER}</Button>
          <Button className={styles.crossBtn}><CloseOutlined /></Button>
          <Button className={styles.csvBtn}>{REWARDS_CONSTANTS.CSV}</Button>
        </div>
        <div className={styles.table}>
          <Table columns={newColumns} dataSource={REWARDS_DATA} pagination={false} />
        </div>
        <div className={styles.paginationContainer}>
          <Pagination
            total={85}
            showTotal={(total) => `Total ${total} items`}
            defaultPageSize={10}
            defaultCurrent={1}
          />
        </div>
      </div>
    </div>
  );
};

export default Rewards;
