import React, { useEffect, useState } from 'react';
import {
  checkEmptyString,
  formatFiatValues,
  handleHideId,
} from '../../utils/common.utils';
import { TransactionFeeService } from '../../services/transaction-fee.service';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import { CONSTANTS } from '../../constants';
import style from '../../Pages/UserDirectory/style.module.scss';
import { Modal, Typography } from 'antd';
import ButtonCommon from '../Buttoncommon/ButtonCommon';
import { useDispatch, useSelector } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice';
import { BankingActions } from '../../redux/features/banking/bankingActions';
import { toast } from 'react-toastify';
import { ComplianceActions } from '../../redux/features/compliance/complianceAtion';
import { setTransactionDetail } from '../../redux/features/compliance/complianceSlice';
import CustomModal from '../CustomModal/CustomModal';
import PermissionCheck from '../PermissionCheck/PermissionCheck';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserActions } from '../../redux/features/user/userActions';
import { setSelectedApiData } from '../../redux/features/user/userSlice';
import { WalletActions } from '../../redux/features/wallet/walletActions';
import ApproveFiatTxnModal from '../../Pages/UserDirectory/transations/ApproveFiatTxnModal';
import { formatTxnType } from '../../services/transaction.service';

const TransactionDetailsController = ({ data, type, clickedType }) => {
  const { transactionDetail } = useSelector((state) => state?.compliance);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [reApproveDetails, setReApproveDetails] = useState(null);
  const [selectedObj, setSelectedObj] = useState([]);
  const [clickedStatus, setClickedStatus] = useState('');
  const [showCancelFiat, setShowCancelFiat] = useState(false);
  const [reportTransaction, setReportTransaction] = useState(false);
  const [showAllTransactionRejectModal, setShowAllTransactionRejectModal] =
    useState(false);
  const { Title } = Typography;
  const dispatch = useDispatch();
  const getTxnId = data?.txnId;
  const status = data?.kytStatus;
  console.log('data::', data);
  let allObj = [
    {
      title: 'Coin',
      detail: data.coin,
    },
    {
      title: 'Amount',
      detail: (
        <>
          {TransactionFeeService.getFeeAmountAfterPrecision(
            data.coin,
            data.amount
          )}
        </>
      ),
    },
    {
      title: 'Txn ID',
      detail: (
        <>
          {data?.txId ? (
            <>
              {handleHideId(data?.txId)}
              <CopyToClipboard userId={data?.txId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'From',
      detail: (
        <>
          {/* <p onClick={()=>console.log("HElloooo")}>{handleHideId(data.fromAddress)}</p> */}
          {handleHideId(data.fromAddress)}
          <CopyToClipboard userId={data.fromAddress} position='top' />
        </>
      ),
    },
    {
      title: 'To',
      detail: (
        <>
          {data.toAddress ? (
            <>
              {handleHideId(data.toAddress)}
              <CopyToClipboard userId={data.toAddress} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },

    // {
    //   title: 'Status',
    //   detail: (
    //     <>
    //       <>
    //         {data?.hasOwnProperty('trxStatus')
    //           ? data.trxStatus
    //           : CONSTANTS.TRANSACTION_STATUS[data.status]}
    //       </>
    //     </>
    //   ),
    // },
  ];

  let swapObj = [
    {
      title: 'Order Id',
      detail: (
        <>
          {data?.orderId ? (
            <>
              {handleHideId(data?.orderId)}
              <CopyToClipboard userId={data?.orderId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'From Currency',
      detail: data.fromCurrency,
    },
    {
      title: 'From Amount',
      detail: data.fromAmount,
    },
    {
      title: 'To Amount',
      detail: data.toAmount,
    },
    {
      title: 'To Currency',
      detail: data.toCurrency,
    },
    {
      title: 'Status',
      detail: data.status,
    },
    {
      title: 'User ID',
      detail: (
        <>
          {data?.userId ? (
            <>
              {/* {handleHideId(data?.userId)} */}
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => getUserDetails(data?.userId)}
              >
                {handleHideId(data?.userId)}
              </span>
              <CopyToClipboard userId={data?.userId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Fee',
      detail:
        +data.fee === 0
          ? 0
          : `${data.fee} ($${Number(data.feeInUsd).toFixed(6)})`,
    },
  ];

  let fiatObj = [
    {
      title: 'Currency',
      detail: data.currency,
    },
    {
      title: 'Amount',
      detail: (
        <>
          {clickedType?.type === 15
            ? formatFiatValues(data.amountWithFee)
            : formatFiatValues(data.amount)}
        </>
      ),
    },
    {
      title: 'Fee',
      // detail: formatFiatValues(data.fee),
      detail: `${formatFiatValues(data.fee)} ($${Number(
        data.feeInUsd ?? 0
      ).toFixed(4)})`,
    },
    {
      title: 'Txn ID',
      detail: (
        <>
          {data?.txnId ? (
            <>
              {handleHideId(data?.txnId)}
              <CopyToClipboard userId={data?.txnId} position='top' />
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'From Address',
      detail: data.fromAccount ? (
        <>
          <span
            style={
              data.txnType === 'INTERNAL_TRANSFER'
                ? { color: 'blue', cursor: 'pointer' }
                : { color: '#0c253d' }
            }
            onClick={() =>
              data.txnType === 'INTERNAL_TRANSFER' &&
              getUserDetails(data?.fromUserId)
            }
          >
            {handleHideId(data?.fromAccount)}
          </span>
          <CopyToClipboard userId={data?.fromAccount} position='top' />
        </>
      ) : (
        '-'
      ),
    },
    {
      title: 'To Address',
      detail: data.toAccount ? (
        <>
          {/* {handleHideId(data?.toAccount)} */}
          <span
            style={
              data.txnType === 'INTERNAL_TRANSFER'
                ? { color: 'blue', cursor: 'pointer' }
                : { color: '#0c253d' }
            }
            onClick={() =>
              data.txnType === 'INTERNAL_TRANSFER' &&
              getUserDetails(data?.toUserId)
            }
          >
            {handleHideId(data?.toAccount)}
          </span>
          <CopyToClipboard userId={data?.toAccount} position='top' />
        </>
      ) : (
        '-'
      ),
    },
    // {
    //   title: 'KYT Status',
    //   detail: (
    //     <>
    //       <>{status ?? '--'}</>
    //     </>
    //   ),
    // },
    ...(data.txnType !== 'INTERNAL_TRANSFER'
      ? [
          {
            title: 'KYT Status',
            detail: (
              <>
                <>{status ?? '--'}</>
              </>
            ),
          },
        ]
      : []),
    {
      title: 'Transaction Status',
      detail: data.status == 'DONE' ? 'COMPLETED' : data.status,
    },
  ];

  let sameObj = [
    {
      title: 'Type Id',
      detail: data.id,
    },
    {
      title: 'Client ID',
      detail: data.clientId,
    },
    {
      title: 'Coin',
      detail: data.currency,
    },
    {
      title: 'Amount',
      detail:
        data.isCrypto === 1
          ? TransactionFeeService.getFeeAmountAfterPrecision(
              data.currency,
              data.amount
            )
          : formatFiatValues(data.amount),
    },
    {
      title: 'Status',
      detail: data.status,
    },
  ];
  const navigate = useNavigate();
  console.log(
    '![9, 25, 14, 15, 10, 16].includes(data.type)::',
    [9, 25, 14, 15, 10, 26].includes(data.type),
    'data.type::',
    data.type
  );
  const getUserDetails = async (id) => {
    try {
      if (!id) return;
      const data = await dispatch(
        UserActions.getUserDetail({ userId: id })
      ).unwrap();
      if (data && data?.user && data?.kyc) {
        dispatch(setSelectedApiData(data));
        console.log({ viewData: data });
        navigate('/user-directory-view');
      } else {
        toast.error('No data found');
      }
    } catch (error) {}
  };
  // useEffect(() => {
  //   let fee = TransactionFeeService.getFeeAmountAfterPrecision(
  //     data.coin,
  //     data.fee
  //   );
  //   allObj.push(
  //     {
  //       title: 'Fee',
  //       detail: <>{TransactionFeeService.checkIsNumberExponential(fee)}</>,
  //     },
  //     // {
  //     //   title: 'KYT Status',
  //     //   detail: CONSTANTS.KYT_TXN_STATUS[data.kytStatus],
  //     // },
  //     // ...[9,25,14,15,10,16].includes(data.type)

  //     ...![9,25,14,15,10,16].includes(data.type)
  //     ? [
  //         {
  //           title: 'KYT Status',
  //           detail: (
  //             <>
  //               <>{status ?? '--'}</>
  //             </>
  //           ),
  //         },
  //       ]
  //     : []),
  //     {
  //       title: 'Transaction Status',
  //       detail: (
  //         <>
  //           <>
  //             {checkEmptyString(
  //               data?.hasOwnProperty('trxStatus')
  //                 ? data.trxStatus
  //                 : CONSTANTS.TRANSACTION_STATUS[data.status]
  //             )}
  //           </>
  //         </>
  //       ),
  //     }
  //   );
  //   allObj = allObj.sort((a, b) => a.position - b.position);
  // }, [data]);

  useEffect(() => {
    let fee = TransactionFeeService.getFeeAmountAfterPrecision(
      data.coin,
      data.fee
    );

    allObj.push(
      {
        title: 'Fee',
        detail: (
          <>{`${TransactionFeeService.checkIsNumberExponential(fee)} ($${Number(
            data.feeInUsd
          ).toFixed(6)})`}</>
        ),
      },
      // {
      //   title: 'Fee In USD',
      //   detail: <>{Number(data.feeInUsd).toFixed(6)}</>,
      // },
      // Conditionally add "KYT Status" if data.type is NOT in [9, 25, 14, 15, 10, 16]
      ...([9, 25, 14, 15, 10, 26].includes(data.type) === false
        ? [
            {
              title: 'KYT Status',
              detail: (
                <>
                  {/* <>{CONSTANTS.KYT_TXN_STATUS[status] ?? '--'}</> */}
                  {data.kytStatus ?? '--'}
                </>
              ),
            },
          ]
        : []),
      {
        title: 'Transaction Status',
        detail: (
          <>
            {checkEmptyString(
              data?.hasOwnProperty('trxStatus')
                ? data.trxStatus
                : CONSTANTS.TRANSACTION_STATUS[data.status]
            )}
          </>
        ),
      }
    );

    allObj = allObj.sort((a, b) => a.position - b.position);
  }, [data]);

  useEffect(() => {
    createListData(type);
    return () => {
      dispatch(setTransactionDetail({})); // empty transaction state from compliance
    };
  }, [type]);

  const createListData = (type) => {
    if (type === 'DEFAULT') {
      setSelectedObj(sameObj);
    } else if (type === 'CRYPTO') {
      setSelectedObj(allObj);
    } else if (type === 'SWAP') {
      setSelectedObj(swapObj);
    } else if (type === 'FIAT') {
      setSelectedObj(fiatObj);
    }
  };

  const showModal = (data) => {
    setIsModalOpen(true);
    setReApproveDetails(data);
  };
  const ReApprovedFiatComplianceStatus = async () => {
    try {
      dispatch(startLoading());
      let data = {
        transactionId: reApproveDetails?.txnId,
      };
      await dispatch(
        BankingActions.updateComplianceReApproveFiatStatus(data)
      ).unwrap();
      setIsModalOpen(false);
      getFiatTransactionDetails(getTxnId);
      getTxnStatus(getTxnId);
      dispatch(setTransactionDetail({ ...transactionDetail, status: '' }));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };
  const getTxnStatus = async (id) => {
    try {
      const data = await dispatch(
        ComplianceActions.getTransactionStatus({ txnId: id })
      ).unwrap();
      return data;
    } catch (error) {
      toast.error(error);
    }
  };

  const getFiatTransactionDetails = async (txnId) => {
    try {
      const res = await dispatch(
        BankingActions.getFiatTransactionDetails({
          transactionId: txnId,
          apiAccessKey: 'ALL_TRANSACTIONS',
        })
      ).unwrap();
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelReApproveFiatTxb = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    let requestPayload = {};
    try {
      requestPayload = {
        coin: transactionDetail.coinSymbol,
        status: clickedStatus,
        typeId: transactionDetail.typeId,
        amount: transactionDetail.amount,
        type:
          transactionDetail.txnType === 'CRYPTO_WITHDRAW'
            ? 1
            : 'suspicious-trx',
        trxType: transactionDetail.txnType,
        clientId: transactionDetail.clientId,
      };

      await dispatch(startLoading());
      await dispatch(
        ComplianceActions.updateToKytStatus(requestPayload)
      ).unwrap();
      dispatch(setTransactionDetail({}));
    } catch (error) {
    } finally {
      setApproveModalOpen(false);

      await dispatch(finishLoading());
    }
  };

  const sendFormattedTxnType = (type) => {
    switch (type) {
      case 1:
      case 18:
        return 'CRYPTO_WITHDRAW';
      case 10:
      case 26:
        return 'CRYPTO_INTERNAL_WITHDRAW';
    }
  };
  const handleCryptoApprove = async (amount) => {
    console.log('transactionDetail::handleTxnApproval:', transactionDetail);

    try {
      let payload = {
        coin: transactionDetail?.currency,
        typeId: transactionDetail?.txnId,
        type: transactionDetail?.type,
        status: 'COMPLETED',
        clientId: transactionDetail?.clientId,
        trxType: sendFormattedTxnType(transactionDetail?.type),
        transactionId: transactionDetail?.txnId,
        amount: amount,
      };
      dispatch(startLoading());
      const res = await dispatch(
        WalletActions.updateCryptoTxnStatus(payload)
      ).unwrap();
      setReportTransaction(false);
      dispatch(setTransactionDetail({}));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
      // setShowApproveFiatTxn(false);
    }
  };

  const handleCryptoReject = async () => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        WalletActions.updateCryptoTxnStatus({
          coin: transactionDetail?.currency,
          typeId: transactionDetail?.txnId,
          type: transactionDetail?.type,
          status: 'FAILED',
          clientId: transactionDetail?.clientId,
          trxType: sendFormattedTxnType(transactionDetail?.type),
          transactionId: transactionDetail?.txnId,
          // amount:TransactionFeeService.getFeeAmountAfterPrecision(
          //   transactionDetail?.currency,
          //   transactionDetail.amount
          // )
          amount: transactionDetail.amount / 10 ** 8,
        })
      ).unwrap();
      dispatch(setTransactionDetail({}));
      setShowAllTransactionRejectModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const handleFiatApprove = async (fiatAmount) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        BankingActions.cancelFiatTxnStatus({
          status: 'APPROVED',
          transactionId: transactionDetail?.txnId,
          amount: fiatAmount,
          apiAccessKey: 'ALL_TRANSACTIONS',
        })
      ).unwrap();
      setReportTransaction(false);
      dispatch(setTransactionDetail({}));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const handleFiatRejection = async () => {
    if (showAllTransactionRejectModal) {
      try {
        dispatch(startLoading());
        const res = await dispatch(
          BankingActions.cancelFiatTxnStatus({
            status: 'REJECTED',
            transactionId: transactionDetail?.txnId,
            apiAccessKey: 'ALL_TRANSACTIONS',
          })
        ).unwrap();
        dispatch(setTransactionDetail({}));
        setShowAllTransactionRejectModal(false);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(finishLoading());
      }
    }
  };

  const handleCancel = () => {
    setApproveModalOpen(false);
  };

  const updateComplianceFiatStatus = async () => {
    try {
      dispatch(startLoading());
      let data = {
        status: clickedStatus === 'reject' ? 'REJECTED' : 'APPROVED',
        transactionId: transactionDetail?.txnId,
      };
      await dispatch(BankingActions.updateComplianceFiatStatus(data)).unwrap();
      setShowCancelFiat(false);
      dispatch(setTransactionDetail({}));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  console.log('transactionDetail:::', transactionDetail);

  return (
    <>
      <div className={style.detailmodalContent_personalInfo}>
        <Title level={3}>
          Transaction Details
          <PermissionCheck>
            {transactionDetail?.kytStatus === 'APPROVED' &&
              transactionDetail?.status === 'PENDING' &&
              transactionDetail?.adminApproval === 1 && (
                <span
                  style={{
                    float: 'right',
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <ButtonCommon
                    className='filter-btn'
                    reject='approve'
                    onClick={() => {
                      // setApproveModalOpen(true);
                      setClickedStatus('COMPLETED');
                      setReportTransaction(true);
                    }}
                  >
                    <span style={{ color: 'black' }}>Approve</span>
                  </ButtonCommon>
                  <ButtonCommon
                    className='filter-btn'
                    reject='reject'
                    onClick={() => {
                      setShowAllTransactionRejectModal(true);
                    }}
                  >
                    <span style={{ color: 'black' }}>Reject</span>
                  </ButtonCommon>
                </span>
              )}
            {transactionDetail?.type === 'CRYPTO' &&
              transactionDetail?.txnStatus == 'PENDING' && (
                <span
                  style={{
                    float: 'right',
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <ButtonCommon
                    className='filter-btn'
                    reject='approve'
                    onClick={() => {
                      setApproveModalOpen(true);
                      setClickedStatus('COMPLETED');
                    }}
                  >
                    <span style={{ color: 'black' }}>Approve</span>
                  </ButtonCommon>
                  <ButtonCommon
                    className='filter-btn'
                    reject='reject'
                    onClick={() => {
                      setApproveModalOpen(true);
                      setClickedStatus('REJECTED');
                    }}
                  >
                    <span style={{ color: 'black' }}>Reject</span>
                  </ButtonCommon>
                </span>
              )}
            {transactionDetail?.txnStatus === 'PENDING' &&
              transactionDetail?.type === 'FIAT' && (
                <span style={{ float: 'right' }}>
                  <ButtonCommon
                    className='filter-btn'
                    reject='approve'
                    onClick={() => {
                      setShowCancelFiat(true);
                      setClickedStatus('approve');
                    }}
                  >
                    <span style={{ color: 'black' }}>Approve</span>
                  </ButtonCommon>
                  &nbsp;
                  <ButtonCommon
                    className='filter-btn'
                    reject='reject'
                    onClick={() => {
                      setShowCancelFiat(true);
                      setClickedStatus('reject');
                    }}
                  >
                    <span style={{ color: 'black' }}>Reject</span>
                  </ButtonCommon>
                </span>
              )}
          </PermissionCheck>
          {/* CONSTANTS.TRANSACTION_STATUS[data.status] */}
        </Title>
        <div style={{ marginTop: '50px' }}></div>
        <ul style={{ padding: 'unset' }}>
          {selectedObj.map((x, index) => (
            <>
              <li key={index}>
                <span>{x.title}</span>
                <span>{x.detail}</span>
              </li>
            </>
          ))}
        </ul>
        <div style={{ marginTop: '50px' }}></div>
      </div>
      <Modal
        className='logout custom-modal'
        width={545}
        open={isModalOpen}
        onOk={handleCancelReApproveFiatTxb}
        onCancel={handleCancelReApproveFiatTxb}
        centered
        maskClosable={false}
      >
        <div className='modal-inner'>
          <h3>Are you sure want to Re-Approve?</h3>
          <p></p>
          <div className='logout_btn'>
            <ButtonCommon
              text='No'
              width={176}
              height={46}
              transBtn='transBtn'
              custmcls='cursorpointer'
              onClick={handleCancelReApproveFiatTxb}
            />
            <ButtonCommon
              type='primary'
              text='Yes'
              width={176}
              onClick={ReApprovedFiatComplianceStatus}
              customBlueBtn='customBlueBtn'
              height={46}
            />
          </div>
        </div>
      </Modal>
      {/* ********************** When Status is PENDING and want to approve************** */}
      <Modal
        className='logout custom-modal'
        width={545}
        open={approveModalOpen}
        // onOk={handleOk}
        // onCancel={handleCancel}
        centered
        maskClosable={false}
      >
        <div className='modal-inner'>
          <h3>
            Are you sure want to{' '}
            {clickedStatus == 'COMPLETED' ? 'Approve' : 'Reject'}?
          </h3>
          <p></p>
          <div className='logout_btn'>
            <ButtonCommon
              text='No'
              width={176}
              height={46}
              transBtn='transBtn'
              custmcls='cursorpointer'
              onClick={handleCancel}
            />
            <ButtonCommon
              type='primary'
              text='Yes'
              width={176}
              onClick={handleOk}
              customBlueBtn='customBlueBtn'
              height={46}
            />
          </div>
        </div>
      </Modal>

      {/* ********************* For Fiat Approve/Reject ******************** */}
      <CustomModal
        title={`Are you sure, you want to ${clickedStatus} this transaction?`}
        open={showCancelFiat}
        onOk={updateComplianceFiatStatus}
        onCancel={() => setShowCancelFiat(false)}
      ></CustomModal>

      {/* ***************************** Report internal aprove modal********************** */}
      <ApproveFiatTxnModal
        amountToVerify={
          transactionDetail?.isCrypto
            ? TransactionFeeService.getFeeAmountAfterPrecision(
                transactionDetail.currency,
                transactionDetail.amount
              )
            : formatFiatValues(Number(transactionDetail?.amount))
        }
        showApproveFiatTxn={reportTransaction}
        setShowApproveFiatTxn={setReportTransaction}
        callback={
          transactionDetail?.isCrypto ? handleCryptoApprove : handleFiatApprove
        }
        // callback={handleTxnApproval}
      />

      {/* ************** Report internal Reject modal */}
      <CustomModal
        title='Are you sure, you want to cancel this transaction?'
        open={showAllTransactionRejectModal}
        onOk={
          transactionDetail?.isCrypto ? handleCryptoReject : handleFiatRejection
        }
        onCancel={() => setShowAllTransactionRejectModal(false)}
      ></CustomModal>
    </>
  );
};

export default TransactionDetailsController;
