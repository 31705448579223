import { ToastContainer, toast } from 'react-toastify';
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  Router,
} from 'react-router-dom';
import './App.scss';
import Layout from './Layout/Index.jsx';
import Dashboard from './Pages/Dashboard/Dashboard.jsx';
import Login from './Pages/Login/Login';
import Setting from './Pages/Setting/Setting';
import UserDirectory from './Pages/UserDirectory/UserDirectory.jsx';
import UserVerification from './Pages/UserVerification/UserVerification';
import UserDirectoryViewMain from './Pages/UserDirectory/UserDirectoryViewMain.jsx';
import PrivateRoutes from './routes/PrivateRoutes';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { PublicRoutes } from './routes/PublicRoutes';
// import FaitTransactions from "./Pages/TransactionSummary/FaitTransactions.jsx";
import CryptoTransaction from './Pages/TransactionSummary/CryptoTransaction.jsx';
import PendingTransaction from './Pages/TransactionSummary/PendingTransaction.jsx';
import WithdrawTransactions from './Pages/CryptoSummary/WithdrawTransaction.jsx';
import Wallet from './Pages/CryptoSummary/Wallet.jsx';
import SupportCommunication from './Pages/SupportCommunication/SupportCommunication.jsx';
import Logs from './Pages/Activity/Logs.jsx';
import TransactionRecord from './Pages/Accounting/TransactionRecord';
import TransationLimits from './Pages/settingSystem/transations/TransationLimits.jsx';
import Content from './Pages/Marketing/content/Content.jsx';
import Banner from './Pages/Marketing/banner/Banner.jsx';
import TransactionsScreeningMain from './Pages/Compliance/trasactionScreening/TransactionsScreeningMain.jsx';
import UserMangement from './Pages/UserManagement/UserMangement.jsx';
import SetPermission from './Pages/UserManagement/SetPermission.jsx';
import Sanaction from './Pages/Compliance/sanaction/Sanaction.jsx';
import SwapTransactions from './Pages/TransactionSummary/SwapTransactions.jsx';
import PagenotFound from './Erorpages/PagenotFound.jsx';
import { Liquidity } from './Pages/Dashboard/liquidity-assets/Liquidity.jsx';
import UpdateContent from './Pages/Marketing/content/UpdateContent';
import UpdatePermission from './Pages/UserManagement/UpdatePermissions.jsx';
import VerificationsScreeningMain from './Pages/Compliance/verificationsSrceening/VerificationsScreenMain';
import ProtectedRoute from './routes/ProtectedRoute';
import NotAuthorised from './Erorpages/NotAuthorised';
import TicketInfo from './Pages/TicketInfo/TicketInfo.jsx';
import RoleManagement from './Pages/RoleManagement/RoleManagement';
import SanctionVerification from './Pages/Compliance/sanaction/SanctionVerification/SanctionVerification.jsx';
import TransationInfo from './Pages/TransationDetail/TransationInfo';
import TransationAllDetail from './Pages/TransationDetail/TransationAllDetail.jsx';
import AllTransaction from './Pages/TransactionSummary/AllTransaction';
import UserInformation from './Pages/KycVerification/UserInformation/UserInformation';
import KycVerification from './Pages/KycVerification/KycVerification';
import SanctionKyc from './Pages/Compliance/SanctionKyc/SanctionKyc';
import KmsManagement from './Pages/KmsManagement/KmsManagement';
import UpdateKmsManagement from './Pages/KmsManagement/UpdateKmsManagement';
import FiatStransactionScreening from './Pages/TransactionSummary/fiatTransactionsScreening/FiatStransactionScreening';
import TransactionDetails from './Pages/UserDirectory/transations/TransactionDetails';
import KmsManagementVerification from './Pages/KmsManagement/KmsManagementVerification';
import KmsManagementList from './Pages/KmsManagement/KmsManagementList';
import TradeManagement from './Pages/Setting/TradeManagement/TradeManagement';
import ReferralProgram from './Pages/ReferralProgram/ReferralProgram';
import GetOTP from './Pages/OTP/GetOTP';
import Rewards from './Pages/Rewards/Rewards.jsx';
import InReviewTabs from './Pages/TransactionSummary/In-Review/InReviewTabs';

function App() {
  const { isLoading } = useSelector((state) => state.loading);
  const { routes } = useSelector((state) => state.dashboard);

  return (
    <div className='App'>
      {isLoading && (
        <div className='loader'>
          <Spin size='large' />
        </div>
      )}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<PublicRoutes />}>
            <Route index element={<Navigate to='/login' replace />} />
            <Route path={`/login`} element={<Login />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path={`/`} element={<Layout />}>
              <Route index path={`/`} element={<Dashboard />} />
              <Route
                path={`/dashboard`}
                element={
                  <ProtectedRoute route='/dashboard'>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path={`/setting`} element={<Setting />} />
              <Route
                path={`/user-directory`}
                element={
                  <ProtectedRoute route='/user-directory'>
                    <UserDirectory />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/sanction-verification'
                element={
                  <ProtectedRoute route='/sanction-report'>
                    <SanctionVerification />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/user-verification`}
                element={
                  <ProtectedRoute route='/user-verification'>
                    <UserVerification />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/transaction-record`}
                element={
                  <ProtectedRoute route='/transaction-record'>
                    <TransactionRecord />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/user-directory-view`}
                element={
                  <ProtectedRoute route='/user-directory'>
                    <UserDirectoryViewMain />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/get-otp`}
                element={
                  <ProtectedRoute route='/get-otp'>
                    <GetOTP />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/user-directory-view/transactions-main-detail`}
                element={
                  <ProtectedRoute route='/user-directory'>
                    <TransationAllDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/all-transaction`}
                element={
                  <ProtectedRoute route='/all-transaction'>
                    <AllTransaction />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/all-transaction/transactions-main-detail`}
                element={
                  <ProtectedRoute route='/all-transaction'>
                    <TransationAllDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/fiat-transaction`}
                element={
                  <ProtectedRoute route='/fiat-transaction'>
                    {/* <FaitTransactions /> */}
                    <FiatStransactionScreening />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/fiat-transaction/transactions-main-detail`}
                element={
                  <ProtectedRoute route='/fiat-transaction'>
                    <TransationAllDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/crypto-transaction`}
                element={
                  <ProtectedRoute route='/crypto-transaction'>
                    <CryptoTransaction />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/crypto-transaction/transactions-main-detail`}
                element={
                  <ProtectedRoute route='/crypto-transaction'>
                    <TransationAllDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/pending-transaction`}
                element={
                  <ProtectedRoute route='/pending-transaction'>
                    <PendingTransaction />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/swap-transaction'}
                element={
                  <ProtectedRoute route='/swap-transaction'>
                    <SwapTransactions />
                  </ProtectedRoute>
                }
              />

              <Route
                path={'/swap-transaction/transactions-main-detail'}
                element={
                  <ProtectedRoute route='/swap-transaction'>
                    <TransationAllDetail />
                  </ProtectedRoute>
                }
              />
               <Route
                path={'/insufficient-liquidity-txn'}
                element={
                  <ProtectedRoute route='/insufficient-liquidity-txn'>
                    <InReviewTabs />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/withdraw-transaction'}
                element={
                  <ProtectedRoute route='/withdraw-transaction'>
                    <WithdrawTransactions />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/wallet'}
                element={
                  <ProtectedRoute route='/wallet'>
                    <Wallet />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/client-support-communication`}
                element={
                  <ProtectedRoute route='/client-support-communication'>
                    <SupportCommunication />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/role-management`}
                element={
                  <ProtectedRoute route='/role-management'>
                    <RoleManagement />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`/rewards`}
                element={
                  <ProtectedRoute route='/rewards'>
                    <TransationLimits />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/transaction-limits`}
                element={
                  <ProtectedRoute route='/transaction-limits'>
                    <TransationLimits />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/activity`}
                element={
                  <ProtectedRoute route='/activity'>
                    <Logs />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/logs`}
                element={
                  <ProtectedRoute route='/logs'>
                    <Logs />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/content'
                element={
                  <ProtectedRoute route='/content'>
                    <Content />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/content-update'
                element={
                  <ProtectedRoute route='/content'>
                    <UpdateContent />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/banner'
                element={
                  <ProtectedRoute route='/banner'>
                    <Banner />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/transactions-main'
                element={
                  <ProtectedRoute route='/transactions-main'>
                    <TransactionsScreeningMain />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/transactions-main-detail'
                element={
                  <ProtectedRoute route='/transactions-main'>
                    <TransationAllDetail />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/verifications-main'
                element={
                  <ProtectedRoute route='/verifications-main'>
                    <VerificationsScreeningMain />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/user-management'
                element={
                  <ProtectedRoute route='/user-management'>
                    <UserMangement />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/role-management/set-permission'
                element={
                  <ProtectedRoute route='/role-management'>
                    <SetPermission />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/role-management/update-permission'
                element={
                  <ProtectedRoute route='/role-management'>
                    <UpdatePermission />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/trade-management'
                element={
                  <ProtectedRoute route='/trade-management'>
                    <TradeManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/sanction-report'
                element={
                  <ProtectedRoute route='/sanction-report'>
                    <Sanaction />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/sanction-report-verifications-screening'
                element={
                  <ProtectedRoute route='/sanction-report'>
                    <VerificationsScreeningMain />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/sanction-report-kyc'
                element={
                  <ProtectedRoute route='/sanction-report'>
                    <SanctionKyc />
                  </ProtectedRoute>
                }
              />

              <Route
                path={'/liquidity-assets'}
                element={
                  <ProtectedRoute route='/dashboard'>
                    <Liquidity />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/client-support-communication-ticket-info'}
                element={
                  <ProtectedRoute route='/client-support-communication'>
                    <TicketInfo />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/reward'}
                element={
                  <ProtectedRoute route='/reward'>
                    <Rewards />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/kms-management'}
                element={
                  <ProtectedRoute route='/kms-management'>
                    <KmsManagementList />
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/referral-program'}
                element={
                  <ProtectedRoute route='/referral-program'>
                    <ReferralProgram />
                  </ProtectedRoute>
                }
              />
              <Route path={'not-authorized'} element={<NotAuthorised />} />
            </Route>
          </Route>
          <Route path='*' element={<PagenotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
