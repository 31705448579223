import React, { useEffect, useState } from 'react';
import { Table, Pagination, Space } from 'antd';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import TransactionFilters from './TransactionFilters.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { CONSTANTS } from '../../../constants/index.js';
import { formatTxnType } from '../../../services/transaction.service.js';
import {
  formatFiatValues,
  handleHideId,
  iconColor,
  iconSize,
} from '../../../utils/common.utils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { TransactionFeeService } from '../../../services/transaction-fee.service.js';
import CopyToClipboard from '../../../Components/CopyToClipboard/CopyToClipboard.jsx';
import DownloadFile from '../../../Components/Common/DownloadFile.jsx';
import { UserActions } from '../../../redux/features/user/userActions.js';
import { KycActions } from '../../../redux/features/kyc/kycActions.js';
import { setDocList } from '../../../redux/features/kyc/kycSlice.js';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice.js';
import { CheckOutlined, EyeOutlined, CloseOutlined } from '@ant-design/icons';
import CustomModal from '../../../Components/CustomModal/CustomModal.jsx';
import { WalletActions } from '../../../redux/features/wallet/walletActions.js';
import ApproveFiatTxnModal from './ApproveFiatTxnModal.jsx';
import { setTransactionDetail } from '../../../redux/features/compliance/complianceSlice';

const Crypto = ({ data, totalCount, getCryptoTransactions }) => {
  const download = useSelector((state) => state.download);
  const [sortedInfo, setSortedInfo] = useState({});

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterData, setFilterData] = useState({});
  const selectedUserData = useSelector((state) => state.user.data);
  const user = selectedUserData?.user;
  const state = useSelector((state) => state);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [showCancelCryptoTxn, setShowCancelCryptoTxn] = useState(false);
  const [showApproveFiatTxn, setShowApproveFiatTxn] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const getUploadedDocList = async (payload) => {
    try {
      const data = await dispatch(
        KycActions.getKytDocListByTxnId(payload)
      ).unwrap();
      return data;
    } catch (error) {
      return {};
    }
  };

  const getTransactionDetails = async (obj) => {
    let userData = {};
    try {
      dispatch(setDocList({}));
      dispatch(startLoading());
      const clientId =
        location.pathname === '/crypto-transaction'
          ? obj?.clientId
          : selectedUserData?.user?.clientId;
      if (!clientId) return;
      if (clientId) {
        let apiAccessKey =
          location.pathname === '/crypto-transaction'
            ? 'CRYPTO_TRANSACTIONS'
            : 'CLIENT_DIRECTORY';
        userData = await dispatch(
          UserActions.getUserIdByClientId({ clientId: clientId, apiAccessKey })
        ).unwrap();
      }
      let type = checkType(obj);
      console.log('type:::', type, 'obj::', obj);
      let data = {};
      setSelectedType(type);
      if (type === 'DEFAULT') {
        data = obj;
        let userId = '';

        userId = userData.userId;
        let newRes = await dispatch(
          KycActions.getKycTxnDetails({
            typeId: obj?.id,
            apiAccessKey: 'CRYPTO_TRANSACTIONS',
          })
        ).unwrap();
        let uploadDocData = await getUploadedDocList({
          limit: '10',
          offset: '0',
          userId: userId,
          txnId: obj?.id,
          docType: 'ADDITIONAL',
        });

        dispatch(setDocList(uploadDocData));

        navigate('transactions-main-detail', {
          state: {
            data,
            type,
            clickedType: obj?.type,
            typeValue: obj.type,
            sumsubResponse: newRes,
            txnId: obj?.id,
            userId: userId,
          },
        });
      } else {
        let res = null;
        let isInside =
          location.pathname === '/crypto-transaction' ? false : true;
        if (isInside) {
          res = await dispatch(
            UserActions.getCryptoTransactionDetails({
              coin: obj?.currency,
              // clientId: "646ffbcc-bea2-11ee-8f44-42010ac96004",
              clientId,
              type: obj?.type, // WITHDEAW, DEPOSIT
              apiKey: CONSTANTS.ENV.TRANSACTION_KEY,
              typeId: obj?.id,
              from: obj.from,
              to: obj.to,
            })
          ).unwrap();
        } else {
          res = await dispatch(
            WalletActions.getTransactionsDetailsById({
              coin: obj?.currency,
              // clientId: "646ffbcc-bea2-11ee-8f44-42010ac96004",
              clientId,
              type: obj?.type, // WITHDEAW, DEPOSIT
              apiKey: CONSTANTS.ENV.TRANSACTION_KEY,
              typeId: obj?.id,
              from: obj.from,
              to: obj.to,
            })
          ).unwrap();
        }
        let newRes = await dispatch(
          KycActions.getKycTxnDetails({
            typeId: obj?.id,
            apiAccessKey: 'CRYPTO_TRANSACTIONS',
          })
        ).unwrap();

        data = res;
        let userId = '';
        userId = userData.userId;

        let uploadDocData = await getUploadedDocList({
          limit: '10',
          offset: '0',
          userId: userId,
          txnId: obj?.id,
          docType: 'ADDITIONAL',
        });

        dispatch(setDocList(uploadDocData));

        navigate('transactions-main-detail', {
          state: {
            data,
            type,
            clickedType: obj?.type,
            typeValue: obj.type,
            sumsubResponse: newRes,
            txnId: obj?.id,
            userId: userId,
          },
        });
      }
    } catch (error) {
      data = obj;
      navigate('transactions-main-detail', {
        state: {
          data,
          type: 'DEFAULT',
          clickedType: obj?.type,
          typeValue: obj.type,
          sumsubResponse: {},
          txnId: obj?.id,
          userId: userData?.userId,
        },
      });
    } finally {
      dispatch(finishLoading());
    }
  };

  const columns = [
    {
      title: 'Type Id',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        return (
          <>
            {record?.id ? (
              <>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => getTransactionDetails(record)}
                >
                  {handleHideId(record?.id)}
                </span>
                <CopyToClipboard userId={record?.id} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Client Id',
      dataIndex: 'clientId',
      key: 'clientId',
      render: (_, record) => {
        return (
          <>
            {record?.clientId ? (
              <>
                <span>{handleHideId(record?.clientId)}</span>
                <CopyToClipboard userId={record?.clientId} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (_, record) => {
        return typeof record.currency === 'string'
          ? record.currency.toUpperCase()
          : '-';
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => {
        return TransactionFeeService.getFeeAmountAfterPrecision(
          record.currency,
          record.amount
        );
      },
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => {
        return (
          <>
            <p>{formatTxnType(record?.type)}</p>
          </>
        );
      },
    },
    {
      title: 'Created on',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space>
          {record &&
            record?.status === 'PENDING' &&
            record?.approved_by === null &&
            record?.adminApproval === 1 &&
            record?.kytStatus === 'APPROVED' && (
              <>
                <ButtonCommon
                  className='filter-btn'
                  reject='approve'
                  width={45}
                  onClick={() => handleOpenApprovalModal(record)}
                >
                  <CheckOutlined />
                </ButtonCommon>

                <ButtonCommon
                  className='filter-btn'
                  reject='reject'
                  onClick={() => handleOpenRejectModal(record)}
                >
                  <CloseOutlined />
                </ButtonCommon>
              </>
            )}
          <EyeOutlined
            style={{ color: iconColor, fontSize: iconSize }}
            onClick={() => {
              getTransactionDetails(record);
              {
                record?.kytStatus === 'APPROVED' &&
                  record?.status === 'PENDING' &&
                  record?.adminApproval === 1 &&
                  record?.approved_by === null &&
                  dispatch(setTransactionDetail(record));
              }
            }}
          />
        </Space>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handlePageChange = (page, pageSize) => {
    if (!download.isEnabled) {
      setPage(page);
      setPageSize(pageSize);
    }

    let data = {
      clientId:
        location.pathname !== '/crypto-transaction' ? user?.clientId : '',
      coin: '',
      page,
      size: pageSize,
      txStatus: 'ALL',
      type: 'receive,send,internal-deposit,internal-withdraw',
    };

    if (Object.keys(filterData).length > 0) {
      data = { ...filterData, page, size: pageSize };
    }

    getCryptoTransactions(data);
  };
  console.log('filterData::11', filterData);
  const handleOpenRejectModal = (obj) => {
    setShowCancelCryptoTxn(true);
    setSelectedTransaction(obj);
  };

  const checkType = (obj) => {
    let selectedObj = obj;
    let type = '';
    if (selectedObj.type === 11) {
      type = 'DEFAULT';
    } else {
      type = 'CRYPTO';
    }
    return type;
  };

  useEffect(() => {
    const { isEnabled, path, headers, rows } = download;
    if (
      isEnabled &&
      path === 'CRYPTO_TRANSACTIONS' &&
      headers.length === 0 &&
      rows.length === 0
    ) {
      handlePageChange(1, totalCount>1000?999:totalCount);
    }
  }, [download]);

  const handleOpenApprovalModal = (obj) => {
    setShowApproveFiatTxn(true);
    setSelectedTransaction(obj);
  };

  const sendFormattedTxnType = (type) => {
    switch (type) {
      case 1:
      case 18:
        return 'CRYPTO_WITHDRAW';
      case 10:
      case 26:
        return 'CRYPTO_INTERNAL_WITHDRAW';
    }
  };

  const handleTxnApproval = async (cryptoAmount) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        WalletActions.updateCryptoTxnStatus({
          coin: selectedTransaction?.currency,
          typeId: selectedTransaction?.txnId,
          type: selectedTransaction?.type,
          status: 'COMPLETED',
          clientId: selectedTransaction?.clientId,
          trxType: sendFormattedTxnType(selectedTransaction?.type),
          transactionId: selectedTransaction?.txnId,
          amount: cryptoAmount,
        })
      ).unwrap();
      setShowApproveFiatTxn(false);
      console.log({ filterData });
      let data = {};
      if (Object.keys(filterData).length > 0) {
        data = { ...filterData };
      } else {
        data = {
          clientId: selectedTransaction?.clientId,
          coin: '',
          page: 1,
          size: 10,
          txStatus: 'ALL',
          trxnType: 'CRYPTO',
          type: 'receive,send,internal-deposit,internal-withdraw',
        };
      }
      getCryptoTransactions(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
      // setShowApproveFiatTxn(false);
    }
  };

  const handleTxnRjection = async () => {
    try {
      dispatch(startLoading());
      const res = await dispatch(
        WalletActions.updateCryptoTxnStatus({
          coin: selectedTransaction?.currency,
          typeId: selectedTransaction?.txnId,
          type: selectedTransaction?.type,
          status: 'FAILED',
          clientId: selectedTransaction?.clientId,
          trxType: sendFormattedTxnType(selectedTransaction?.type),
          transactionId: selectedTransaction?.txnId,
        })
      ).unwrap();
      setShowCancelCryptoTxn(false);
      console.log({ filterData });
      let data = {};
      if (Object.keys(filterData).length > 0) {
        data = { ...filterData };
      } else {
        data = {
          clientId: selectedTransaction?.clientId,
          coin: '',
          page: 1,
          size: 10,
          txStatus: 'ALL',
          trxnType: 'CRYPTO',
          type: 'receive,send,internal-deposit,internal-withdraw',
        };
      }
      getCryptoTransactions(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
      // setShowCancelCryptoTxn(false);
    }
  };

  return (
    <>
      <div className='table-design'>
        <div className='title-filter flexEnd'>
          <div className='title-filter_rightSec'>
            <TransactionFilters
              setFilterData={setFilterData}
              pageSize={pageSize}
              getCryptoTransactions={getCryptoTransactions}
              clientId={user?.clientId}
              setPage={setPage}
              data={data}
            />

            <DownloadFile
              fileName={`crypto_txns_${new Date().toDateString()}_${new Date().toTimeString()}.csv`}
              type='CSV'
              path='CRYPTO_TRANSACTIONS'
            />
          </div>
        </div>
        <div className='custom-table'>
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            pagination={false}
            sortDirections={['ascend', 'descend', 'ascend']}
          />
        </div>
      </div>
      <div className='pagination_block'>
        <span className='records' style={{ marginTop: '10px' }}>
          Total Records: {totalCount}
        </span>

        {totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={page}
          />
        )}
      </div>
      <CustomModal
        title='Are you sure, you want to cancel this transaction?'
        open={showCancelCryptoTxn}
        onOk={handleTxnRjection}
        onCancel={() => setShowCancelCryptoTxn(false)}
      ></CustomModal>
      {console.log(
        'selected transaction in crypto reports ?:',
        selectedTransaction
      )}
      <ApproveFiatTxnModal
        amountToVerify={
          selectedTransaction?.isCrypto
            ? TransactionFeeService.getFeeAmountAfterPrecision(
                selectedTransaction.currency,
                selectedTransaction.amount
              )
            : formatFiatValues(
                Number(selectedTransaction?.amount) +
                  Number(selectedTransaction?.fee)
              )
        }
        showApproveFiatTxn={showApproveFiatTxn}
        setShowApproveFiatTxn={setShowApproveFiatTxn}
        callback={handleTxnApproval}
      />
    </>
  );
};

export default Crypto;
