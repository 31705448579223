import styles from "./style.module.scss"; 
import { CONSTANTS } from '../../../constants/index.js';
import { formatNumberLiquidityMngmt } from '../../../constants/commonFunction';
const CurrencyBalance = ({ available, pending, isCrypto = true }) => {

    const { LIQUIDITY_MANAGEMENT_CONSTANTS } = CONSTANTS;

return(
    <div style = {{ display: 'flex', justifyContent: 'center', alignItems:'right', flexDirection: 'column'}}>
                        <div className={styles.row}>
                            <div style={{ width: 250, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <div style={{ fontSize: 16, textAlign: 'right', fontFamily:'monospace' }}>{formatNumberLiquidityMngmt(available, isCrypto)}</div>
                            </div>
                            <div style={{fontSize: 12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.AVAILABLE}</div>
                        </div>
                        <div className={styles.row}>
                            <div style={{ width: 250, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <div style={{ fontSize: 16, textAlign: 'right', fontFamily:'monospace' }}>{formatNumberLiquidityMngmt(pending, isCrypto)}</div>
                            </div>
                            <div style={{fontSize: 12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.PENDING}</div>
                        </div>
                        <div className={styles.row}>
                            <div style={{ width: 250, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <div  className={`${available-pending < 0 ? styles.negative : ''}`} style={{fontWeight: 700, fontSize: 20,fontFamily:'monospace' }}>= {formatNumberLiquidityMngmt(available-pending, isCrypto)}</div>
                            </div>
                            <div style={{fontSize: 12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.ACTUAL}</div>
                        </div>
                    </div>
)
};

export default CurrencyBalance;