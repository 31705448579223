import React, { useState, useEffect } from 'react';
import { Table, Tooltip } from 'antd';
import Question from '../../../Assets/Images/Question.svg'
import { CONSTANTS } from '../../../constants/index.js';
import styles from "./style.module.scss"; 
import { formatNumberWithoutDecimals } from '../../../constants/commonFunction';

const LiquidityManagement = ({ cryptodata, narvidata }) => {

  const { LIQUIDITY_MANAGEMENT_CONSTANTS } = CONSTANTS;

  const sortedData = cryptodata.sort((a, b) => {
    const coinA = a.coin.split('(')[0].toUpperCase();
    const coinB = b.coin.split('(')[0].toUpperCase();
    return coinA.localeCompare(coinB); // Ascending order
  });

  // Calculate total liquidity including narvidata
  const totalLiquidity = sortedData.reduce((total, record) => {
    return total + Number(record.usdValue || 0);
  }, 0) + narvidata.reduce((total, record) => {
    return total + Number(record.usdValue || 0);
  }, 0);

  const totalLiabilities = sortedData.reduce((total, record) => {
    return total + Number(record.pendingUSDValue || 0);
  }, 0) + narvidata.reduce((total, record) => {
    return total + Number(record.pendingUSDValue || 0);
  }, 0);

  // Calculate total liabilities
  const totalAvailable = totalLiquidity-totalLiabilities;
  
  // Define rows for the first column labels and map data accordingly
  const firstColumnLabels = [LIQUIDITY_MANAGEMENT_CONSTANTS.NETWORK_CUR, LIQUIDITY_MANAGEMENT_CONSTANTS.LIQUIDITY, LIQUIDITY_MANAGEMENT_CONSTANTS.LIABILITIES, LIQUIDITY_MANAGEMENT_CONSTANTS.AVAILABLE];

  const columns = [
       {
      key: 'labels',
      dataIndex: 'labels',
      width: 64,
      render: (_, record) => (
        <div style={{color:'#9EA8B1'}}>
          {record.label}
        </div>
      )
    },
    ...narvidata.map(record => ({
      dataIndex: record.currency,
      key: record.currency,
      width: 92,
      align: 'right',
      render: (_, row) => {
        let content;
        const style = { fontWeight: 600 };
        if (row.label !== LIQUIDITY_MANAGEMENT_CONSTANTS.NETWORK_CUR) {
          style.fontFamily = 'monospace'; 
        }
        switch (row.label) {
          case LIQUIDITY_MANAGEMENT_CONSTANTS.NETWORK_CUR:
            content = `${LIQUIDITY_MANAGEMENT_CONSTANTS.NARVI}, ${record.currency.toUpperCase()}`;
            break;
          case LIQUIDITY_MANAGEMENT_CONSTANTS.LIQUIDITY:
            content =  `$${formatNumberWithoutDecimals(record.usdValue)}`;
            break;
          case LIQUIDITY_MANAGEMENT_CONSTANTS.LIABILITIES:
            content = `$${formatNumberWithoutDecimals(record.pendingUSDValue)}`; 
            break;
            case LIQUIDITY_MANAGEMENT_CONSTANTS.AVAILABLE:

              const available = record.usdValue - (record.pendingUSDValue || 0);

              content = available >= 0
              ? `$${formatNumberWithoutDecimals(available)}`
              : `-$${formatNumberWithoutDecimals(Math.abs(available))}`;
              break;
          default:
            content = '';
        }    
        if (row.label === LIQUIDITY_MANAGEMENT_CONSTANTS.LIQUIDITY && record.usdValue < 0) {
          style.color = '#A81616';
        } else if (row.label === LIQUIDITY_MANAGEMENT_CONSTANTS.LIABILITIES && record.pendingUSDValue < 0) {
          style.color = '#A81616';
        } else if (row.label === LIQUIDITY_MANAGEMENT_CONSTANTS.AVAILABLE && record.usdValue-record.pendingUSDValue < 0) {
          style.color = '#A81616';
        }
  
        return <span style={style}>{content}</span>;
        }
      })),
      ...sortedData.map(record => ({
      dataIndex: record.coin,
      key: record.coin,
      width: 92,
      align: 'right',
      render: (_, row) => {
        let content;
        const style = { fontWeight: 600 };
        if (row.label !== LIQUIDITY_MANAGEMENT_CONSTANTS.NETWORK_CUR) {
          style.fontFamily = 'monospace'; 
        }
        switch (row.label) {
          case LIQUIDITY_MANAGEMENT_CONSTANTS.NETWORK_CUR:
            content = `${record.network}, ${record.coin.split('(')[0].toUpperCase()}`;
            break;
          case LIQUIDITY_MANAGEMENT_CONSTANTS.LIQUIDITY:
            content =  `$${formatNumberWithoutDecimals(record.usdValue)}`;
            break;
          case LIQUIDITY_MANAGEMENT_CONSTANTS.LIABILITIES:
            content = `$${formatNumberWithoutDecimals(record.pendingUSDValue)}`;
            break;
            case LIQUIDITY_MANAGEMENT_CONSTANTS.AVAILABLE:
              // Calculate Available balance
              const available = record.usdValue - (record.pendingUSDValue || 0);
              // Format Available content
              content = available >= 0
              ?  `$${formatNumberWithoutDecimals(available)}`   
              :  `-$${formatNumberWithoutDecimals(Math.abs(available))}`; 
              break;
          default:
            content = '';
        }

         // Check if the value is negative and apply color if so
      if (row.label === LIQUIDITY_MANAGEMENT_CONSTANTS.LIQUIDITY && record.usdValue < 0) {
        style.color = '#A81616';
      } else if (row.label === LIQUIDITY_MANAGEMENT_CONSTANTS.LIABILITIES && record.pendingUSDValue < 0) {
        style.color = '#A81616';
      } else if (row.label === LIQUIDITY_MANAGEMENT_CONSTANTS.AVAILABLE && record.usdValue-record.pendingUSDValue < 0) {
        style.color = '#A81616';
      }

      return <span style={style}>{content}</span>;
      }
    })),
   
  ];

   const dataSource = firstColumnLabels.map(label => ({ key: label, label }));

    const [minutesAgo, setMinutesAgo] = useState(0);
  
    useEffect(() => {
      // Update the minutes every minute
      const intervalId = setInterval(() => {
        setMinutesAgo(prevMinutes => prevMinutes + 1);
      }, 60000); // 60000 ms = 1 minute
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, []);

 return (
  <div style = {{ display: 'flex', flexDirection: 'column', gap: 24 }}>
    <div style={{display: 'flex', justifyContent: 'space-between',flexDirection:'row', gap: 32 }}>
      <div style = {{fontSize: 52, fontWeight: 700}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.LIQUIDITY_MANAGEMENT}</div>
      <div style = {{display: 'flex', flexDirection:'row', alignContent:'right'}}>
        <div style = {{ display: 'flex', flexDirection: 'column', width:100}}>
              <div style={{fontSize:12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.UPDATED}</div>
              <div style={{fontSize:16}}>{minutesAgo} min ago</div>
        </div>
        <div style = {{ display: 'flex', flexDirection: 'column', width:100}}>
              <div style={{fontSize:12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.START_TIME}</div>
              <div style={{fontSize:16}}>09:00 UTC</div>
        </div>
        <div style = {{ display: 'flex', flexDirection: 'column', width:100}}>
              <div style={{fontSize:12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.END_TIME}</div>
              <div style={{fontSize:16}}>16:00 UTC</div>
        </div>
        <div style={{backgroundColor:'#FFEFC5', borderRadius:12, paddingTop:11,
          paddingBottom:11, paddingRight: 16, paddingLeft:16, height:40
        }}>
            CSV
        </div>
      </div>  
    </div>
    <div style={{ display: 'flex', alignItems: 'stretch', gap: 12 }}>
      <div className={styles.labelInABox} style={{ flex: 1 }}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered={false}
          className='custom-liquidity-table'
          showHeader={false}
        />
        </div>
        <div className={styles.labelInABox} style={{width: '300px', 
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'flex-end', 
                                                gap: 16,
                                                justifyContent: 'center',
                                                paddingTop: 20,
                                                paddingBottom:20,
                                                paddingRight:30,
                                                paddingLeft: 30
                                                }}> 
          <div style={{gap: 4,textAlign: 'right'}}>
            <div style={{fontSize:12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.TOTAL_LIQUIDITY}</div>
            <div style={{ fontWeight: 700, fontSize: 24}}>${formatNumberWithoutDecimals(totalLiquidity)}</div>
          </div>
          <div style={{gap:4,textAlign: 'right'}}>
            <div>{LIQUIDITY_MANAGEMENT_CONSTANTS.TOTAL_LIABILITIES}</div>
            <div style={{ fontWeight: 700, fontSize: 24 }}>${formatNumberWithoutDecimals(totalLiabilities)}</div>
          </div>
          <div style={{gap:4, textAlign: 'right'}}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', gap: 4 }}>
              <div>{LIQUIDITY_MANAGEMENT_CONSTANTS.TOTAL_AVAILABLE_BALANCE}</div>
              <Tooltip placement={'top'} title={
                 <>
                 $ {formatNumberWithoutDecimals(totalLiquidity)} - $ {formatNumberWithoutDecimals(totalLiabilities)}<br />
                 {LIQUIDITY_MANAGEMENT_CONSTANTS.TOTAL_LIQUIDITY_MINUS_TOTAL_LIABILITIES}
                </>
              }>
                <img
                    src={Question}
                    alt="logo"
                    width='10.5'
                    height='10.5'
                />
              </Tooltip>
            </div>
            <div style={{ fontWeight: 700, fontSize: 24, color: totalAvailable < 0 ? '#A81616' : 'inherit',}}>
             
              {totalAvailable < 0 
                ?  `-$${formatNumberWithoutDecimals(Math.abs(totalAvailable))}` 
              : `$${formatNumberWithoutDecimals(totalAvailable)}` }
              </div>
          </div>
        </div>
    </div>
    
  </div>
  );
};
export default LiquidityManagement;
