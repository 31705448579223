import React, { useCallback, useEffect, useState } from 'react';
import Currencylist from '../../Components/currencycard/Currencylist.jsx';
import Welcomecomp from '../../Components/WelcomeComponent/Welcomecomp';
import UserList from '../../Components/usercard/Userlist';
import styles from './style.module.scss';
import Headingview from '../../Components/headingview/Headingview';
import SupportTicket from '../../Components/supportTable/SupportTicket';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../redux/features/user/userActions.js';
import Slickslider from '../../Components/Slider/Slickslider.jsx';
import {
  finishLoading,
  startLoading,
} from '../../redux/features/loading/loadingSlice.js';
import { WalletActions } from '../../redux/features/wallet/walletActions.js';
import { toast } from 'react-toastify';
import Clients from './clients/Client.jsx';
import DashboardTransactions from './transactions/DashboardTransactions.jsx';
import Fees from './Fees/Fees.jsx';
import { BankingActions } from '../../redux/features/banking/bankingActions.js';
import { CONSTANTS } from '../../constants/index.js';

const { useGetLiquidityAndAssetsQuery } =
  WalletActions.getLiquidityAndAssetsRtk;

function Dashboard() {
  const { error, data, isLoading, isFetching, isError } =
    useGetLiquidityAndAssetsQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });
  const dispatch = useDispatch();
  const [liquidities, setLiquidities] = useState([]);
  const [totalLiquidity, setTotalLiquidity] = useState(0);
  const [clientTransactionData, setClientTransactionsData] = useState({});
  const [userCountData, setUserCountData] = useState({
    user: {
      lastSevenDaysUserCount: 0,
      lastThirtyDaysUserCount: 0,
      totalBusinessUserCount: 0,
      totalIndividualUserCount: 0,
      totalUserCount: 0,
      totalVerifiedUserCount: 0,
    },
  });

  const getFiatBalances = async () => {
    try {
      // dispatch(startLoading());
      let res = await dispatch(BankingActions.getFiatBalances()).unwrap();
      console.log({ response: res });
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      // dispatch(finishLoading());
    }
  };

  const getUserCounts = useCallback(async () => {
    try {
      const res = await dispatch(UserActions.getUserCounts()).unwrap();
      if (res.user) setUserCountData({ user: res.user });
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  // const getLiquidityAssets = async () => {
  //   try {
  //     // refetch(true)

  //     if (isLoading) {
  //       await dispatch(startLoading());
  //     } else {
  //       const { adminWalletBalance, cmcPrices } = data.response.data;
  //       if (
  //         adminWalletBalance &&
  //         adminWalletBalance.length > 0 &&
  //         cmcPrices &&
  //         cmcPrices.length > 0
  //       ) {
  //         const [...newData] = await addUsdValueInWallets(
  //           adminWalletBalance,
  //           cmcPrices
  //         );
  //         setLiquidities(newData);
  //       }
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   } finally {
  //     await dispatch(finishLoading());
  //   }
  // };
  //
  const addUsdValueInWallets = async (adminWalletBalance, cmcPrices) => {
    let fiatBalances = await getFiatBalances();
    const newArray = [];
    let totalUsdValue = 0;
    let fiatCurrencyData = fiatBalances ? fiatBalances[0] : [];
    let euroBalance = fiatCurrencyData?.balance / 100;

    const cmcFiat = cmcPrices.find((cmc) => {
      return cmc.cmcId.toLowerCase() === 'eur';
    });
    let euroUsdPrice = euroBalance * cmcFiat.usdPrice;
    console.log('cmcFiat::', cmcFiat);
    let fiatPayload = {
      img: (
        <img
          src={`${
            CONSTANTS.ENV.KYC_DOCS_URL
          }/stage-fcbank/all_icons/${fiatCurrencyData?.currency?.toLowerCase()}.png`}
        />
      ),
      heading: fiatCurrencyData?.currency.toUpperCase(),
      data: '',
      rightdata: `$${Number(euroUsdPrice).toFixed(2)}` || 0.0,
      balance: `${Number(euroBalance).toFixed(2)}`,
    };

    for (const wallet of adminWalletBalance) {
      let walletCoin = wallet.coin.toLowerCase();
      walletCoin = walletCoin.split('(')[0];
      console.log('walletCoin', { adminWalletBalance, cmcPrices });

      if (walletCoin === 'mat-usdt') walletCoin = 'usdt';
      else if (walletCoin === 'mat-usdc') walletCoin = 'usdc';

      const cmc = cmcPrices.find((cmc) => {
        console.log('wallet', wallet.balance, cmc.usdPrice);
        return cmc.cmcId.toLowerCase() === walletCoin.toLowerCase();
      });

      let walletUsdValue = 0;
      if (cmc) walletUsdValue = wallet.balance * cmc.usdPrice || 0.0;

      // Accumulate the wallet USD value

      newArray.push({
        img: <img src={wallet.iconUrl} alt={walletCoin} width={60} />,
        heading: walletCoin.toUpperCase(),
        data: wallet.network.toUpperCase(),
        rightdata: `$${walletUsdValue.toFixed(2)}`,
        balance: `${Number(wallet.balance).toFixed(8)}`,
      });
      totalUsdValue += walletUsdValue;
      console.log('newArray', newArray);
      // if (newArray.length > 6) break;
      console.log({ balances: walletUsdValue.toFixed(2), coin: walletCoin });
      setTotalLiquidity(Number(totalUsdValue + euroUsdPrice).toFixed(2));
    }
    newArray.push(fiatPayload);
    return newArray;
  };

  const getClientsData = async () => {
    try {
      // await dispatch(startLoading());
      const res = await dispatch(WalletActions.getClientsAndTransactions());
      setClientTransactionsData(res?.payload);
    } catch (error) {
      toast.error(error);
    } finally {
      // await dispatch(finishLoading());
    }
  };

  const getLiquidityAndAssetsValue = async () => {
    try {
      await dispatch(startLoading());
      const res = await dispatch(WalletActions.getLiquidityAssets());
      const { adminWalletBalance, cmcPrices } = res.payload;
      if (
        adminWalletBalance &&
        adminWalletBalance.length > 0 &&
        cmcPrices &&
        cmcPrices.length > 0
      ) {
        const [...newData] = await addUsdValueInWallets(
          adminWalletBalance,
          cmcPrices
        );
        setLiquidities(newData);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  // useEffect(() => {
  // if (data) getLiquidityAssets();
  // }, [data, isLoading, isError]);

  useEffect(() => {
    getUserCounts();
  }, [getUserCounts]);

  useEffect(() => {
    getClientsData();
    getLiquidityAndAssetsValue();
  }, []);

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboard_sec}>
        <div className={styles.dashboard_sec__left}>
          {/* <Welcomecomp /> */}
          <section>
            {/* <Slickslider /> */}
            <Clients clientTransactionData={clientTransactionData} />
          </section>
          <section style={{ marginTop: '20px' }}>
            {/* <UserList userCountData={userCountData} /> */}
            <DashboardTransactions
              clientTransactionData={clientTransactionData}
            />
          </section>
          <section style={{ marginTop: '20px' }}>
            {/* <UserList userCountData={userCountData} /> */}
            <Fees clientTransactionData={clientTransactionData} />
          </section>
          <section>
            <Headingview
              heading={`Liquidity & Assets $${totalLiquidity}`}
              title='View More'
              link='/liquidity-assets'
            />
            {liquidities.length > 0 ? (
              <Currencylist liquidities={liquidities} />
            ) : (
              <p
                style={{
                  textAlign: 'center',
                  margin: '30px',
                  color: 'blue',
                  cursor: 'pointer',
                }}
                onClick={getLiquidityAndAssetsValue}
              >
                Refresh Liquidity
              </p>
            )}
          </section>
          <section>
            <Headingview
              heading='Support Tickets'
              title='View All'
              link='/client-support-communication'
            />
            <SupportTicket />
          </section>
        </div>
      </div>
    </div>
  );
  // return <></>
}

export default Dashboard;
