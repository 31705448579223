import React, {useState} from 'react';
import './InfoCard.scss';
import CopyIcon from '../../Assets/Images/copyIcon.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'antd';

const InfoCard = (point) => {

    const {title, detail, titleSub, isCopy = false,
        detailcolor = null,
    } = point;
    
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000); // Reset copied state after 2 seconds
      };

    return (
        <div className='infoCard'>
            <span className='fieldNameValue ant-typography'>
                <div className='fieldName ant-typography' >{title}</div>
                <div className='fieldValue ant-typography' style={{color: detailcolor}}>{detail}
                {isCopy && (
                    <span>
                    <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
                        <CopyToClipboard text={detail} onCopy={handleCopy}>
                        <img
                            src={CopyIcon}
                            alt="Copy"
                            style={{ cursor: 'pointer', width: '14px', height: '14px', marginLeft: '5px' }} // Add margin for spacing
                        />
                        </CopyToClipboard>
                    </Tooltip>
                    </span>
                )}
                </div>
            </span>
       
            {titleSub && <div className='fieldSubValue'>{titleSub}</div>}
            {/* {point.detail && <div className='fieldDetail'>{point.detail}</div>} */}
        </div>
    );
}


export default InfoCard;