import React, { useEffect, useState } from 'react';
import TransationAbout from './TransationAbout';
import TransationInfo from './TransationInfo';
import MatchRule from './MatchRule';
import { useLocation } from 'react-router-dom';
import TransactionDetailsController from '../../Components/TransactionDetailsController/TransactionDetailsController';
import KycUploadKYT from '../../Components/KycUploadKYT/KycUploadKYT';
import { useSelector } from 'react-redux';
import { Utils } from '../../utils';
import {
  createFullName,
  formatAdminNameWithRole,
  formatDateTime,
} from '../../utils/common.utils';
import { FilePdfOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Divider, Image, Typography } from 'antd';
import { Enviroments } from '../../constants/environment';
import UserInfo from '../KycVerification/UserInformation/UserInfo';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Modal from 'react-modal';

const { Title } = Typography;

const PDFViewer = ({ url }) => (
  <div style={{ height: 'auto', width: '100%' }}>
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}
    >
      <Viewer fileUrl={url} />
    </Worker>
  </div>
);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
  },
};

const ModalPdfViewer = ({ isOpen, onRequestClose, pdfUrl }) => {
  console.log('modal open', pdfUrl);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="PDF Viewer"
    >
      <div
        onClick={onRequestClose}
        style={{ marginBottom: '10px', cursor: 'pointer' }}
      >
        <CloseOutlined
          style={{ fontSize: '30px', color: 'lightgrey', fontWeight: '400' }}
        />
      </div>
      <PDFViewer url={pdfUrl} />
    </Modal>
  );
};

const TransationAllDetail = () => {
  const location = useLocation();
  const data = location.state?.data;

  console.log('TXN DETAIL : ',data);
  

  const type = location.state?.type;
  const typeValue = location.state?.typeValue;
  const clickedType = location.state?.clickedType;
  const sumsubData = location.state?.sumsubResponse;
  const userId = location.state?.userId;
  const txnId = location.state?.txnId;
  
  const { txnDocList } = useSelector((state) => state.kyc);
  const [documentDocs, setDocumentDocs] = useState([]);
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [docsLoaded, setDocsLoaded] = useState(false);
  const [selectedPdfData, setSelectedPdfData] = useState({
    url: '',
  });

  const kycDocUrl = Enviroments.KYC_DOCS_URL;
  const mb20 = { margin: '100px 0' };

  const openModal = (url) => {
    setSelectedPdfData({
      ...selectedPdfData,
      url: url,
    });
    setIsPdfOpen(true);
  };
  const closeModal = () => setIsPdfOpen(false);

  const documentData = async () => {
    let updatedUserUploadedDocs = [];
    let kycDocData = [];
    if (txnDocList?.trasnactionDocs?.length > 0) {
      updatedUserUploadedDocs = txnDocList?.trasnactionDocs?.map(
        (uploadedDoc) => {
          return {
            ...uploadedDoc,
            docPath: uploadedDoc.keyValue,
            docName: uploadedDoc.mediaTitle,
            txnId: uploadedDoc.mediaId,
            log: {},
          };
        }
      );
      kycDocData = [...updatedUserUploadedDocs];
    }

    if (txnDocList?.rows && txnDocList?.rows?.length > 0) {
      kycDocData = [...kycDocData, ...txnDocList?.rows];
    }

    let docs = kycDocData?.map(async (doc, index) => {
      let fullDocPath = kycDocUrl + doc?.docPath;
      let ext = fullDocPath.substring(fullDocPath.lastIndexOf('.'));
      let isPdf = false;
      let pdfTarget = '';
      if (ext === '.pdf') {
        isPdf = true;
        pdfTarget = fullDocPath;
      }

      let { data } = await axios.get(kycDocUrl + doc?.docPath, {
        responseType: 'blob',
      });

      data = await Utils.common.blobToBase64(data);

      let fullName = createFullName(
        doc.log?.admin?.firstName,
        doc.log?.admin?.lastName
      );
      let role = doc.log?.admin?.title;

      return {
        title: (
          <>
            <p>{doc.docName}</p>
            {!!fullName && !!role && doc.docType === 'ADDITIONAL' && (
              <>
                <p>
                  <strong>Uploaded By :- </strong>
                  {formatAdminNameWithRole(fullName, role)}
                </p>
                <p>
                  <strong>Uploaded On :- </strong>
                  {formatDateTime(doc.log?.updatedAt)}
                </p>
              </>
            )}
          </>
        ),
        about: !isPdf ? (
          <>
            <div style={{ display: "flex", justifyContent: 'end' }}>
              <Image
                src={data}
                alt="img"
                style={{ width: '150px', height: 'auto' }}
                className="cursorpointer"
              />
            </div>
          </>
        ) : (
          <>
            {isPdfOpen == false && (
              <div
                onClick={() => openModal(data)}
                style={{ cursor: 'pointer' }}
              >
                <FilePdfOutlined style={{ fontSize: '50px' }} />
              </div>
            )}
          </>
        ),
        docType: doc.docType,
        id: doc.id,
        docName: doc.docName,
        userId
      };
    });
    
    docs = await Promise.all(docs);

    setDocsLoaded(true);
    setDocumentDocs(docs);
  };

  useEffect(() => {
    documentData();
  }, [txnDocList]);
  console.log("typeValue::",typeValue);
// console.log("typeValue:::",![9, 14].includes(typeValue) && type !== 'SWAP',typeValue);
  return (
    <div>
      {data && Object.keys(data).length > 0 && (
        <TransactionDetailsController
          type={type}
          data={data}
          clickedType={clickedType}
        />
      )}

      {type !== 'SWAP' && (
        <>
          <KycUploadKYT userData={{ userId }} txnId={txnId} />
          {!docsLoaded ? (
            <p>Loading...</p>
          ) : (
            <>
              {documentDocs.length > 0 && (
                <>

                  <UserInfo checkdata="" list={documentDocs} type="promise" />
                </>
              )}
            </>
          )}
        </>
       )} 

      {sumsubData && Object.keys(sumsubData).length > 0 && (
        <>
          <Divider style={mb20} />
          <TransationAbout data={sumsubData} updatedAt={data?.updatedAt} />
          <TransationInfo data={sumsubData} />
          <MatchRule data={sumsubData} />
        </>
      )}

      {/* <Event data={location.state} /> */}
      <ModalPdfViewer
        isOpen={isPdfOpen}
        onRequestClose={closeModal}
        pdfUrl={selectedPdfData.url}
      />
    </div>
  );
};

export default TransationAllDetail;
