import InfoCard from "../../../Components/Card/InfoCard";
import styles from "./style.module.scss"; 
import CurrencyBalance from "./CurrencyBalance";
import { CONSTANTS } from "../../../constants";

const NetworkCard = ({ networkItems }) => {

	const { LIQUIDITY_MANAGEMENT_CONSTANTS } = CONSTANTS;
    const gasThreshold = 5000;

    console.log("networkItems from NetworkCard:", networkItems);

    const networkLength = networkItems.length;

    // Calculate total balance and total USD value
    const totalPendingValue = networkItems.reduce((acc, item) => acc + (item.pendingUSDValue || 0), 0);
    const totalUsdValue = networkItems.reduce((acc, item) => acc + item.usdValue, 0);
    const totalPendingTxs = networkItems.reduce((acc, item) => acc + (item.pendingTx), 0);

    const coin = networkItems[0]?.coin.split('(')[0].toUpperCase(); 

    return(
    <div className={styles.labelInABoxShadow} style={{marginTop: 24, display: 'flex', flexDirection:'row',  flex: 1, paddingBottom:32, paddingTop: 20
    }}>
           <div style={{flex:1}}>
                <div style = {{display: 'flex', flexDirection:'row', alignContent:'right'}}>
                    <div style = {{display: 'flex', flexDirection: 'column', gap:12}}>
                        <div style={{fontSize:24}}>{networkItems[0]?.network}</div>
                            {networkItems[0]?.centralAddress && (
                                <InfoCard key="key1" title={LIQUIDITY_MANAGEMENT_CONSTANTS.WALLET_ACCOUNT_NUMBER} detail={networkItems[0].centralAddress} isCopy={true} />
                            )}
                        {networkItems[0]?.centralGasAddress && (
                            <InfoCard key="key2" title={`${LIQUIDITY_MANAGEMENT_CONSTANTS.GAS_WALLET}:`} detail={networkItems[0].centralGasAddress} isCopy={true} />
                            )}
                            {networkItems[0]?.gasFeeBalance !== undefined && (
                            <InfoCard key="key3" title={`${LIQUIDITY_MANAGEMENT_CONSTANTS.GAS_TOKEN_AMOUNT}`} detail={`${networkItems[0].gasFeeBalance} ${coin}`} />
                            )}
                            {networkItems[0]?.gasUSDValue !== undefined && (
                                <InfoCard key="key4" title={`${LIQUIDITY_MANAGEMENT_CONSTANTS.GAS_TOKEN_VALUE}, $`} detail={`$${Number(networkItems[0].gasUSDValue).toLocaleString()}`}
                                {...(networkItems[0].gasUSDValue < gasThreshold ? { detailcolor: "#A81616" } : {})} />
                            )}
                    </div>
                </div>  
           </div>

            <div style={{flex:2}}>
                <div style = {{display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginTop:20}}>
                    <div style = {{ display: 'flex', flexDirection: 'row', gap: 12, width: 120}}></div>
                    {['Currency Balance', 'Currency Balance, $'].map((text, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <div className={styles.row}>
                                <div style={{ width: 250, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                    <div style={{ fontSize: 12, textAlign: 'right', paddingBottom: 12 }}>{text}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div style = {{ display: 'flex', justifyContent: 'flex-start',alignItems: 'flex-end', flexDirection: 'column', width:100}}>
                        <div style={{fontSize: 12, paddingBottom:12}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.PENDING_TX}</div>
                    </div>
                </div>
            <div>
            <div style={{flex:2}}>
            {/* Network Items Section */}
            {networkItems.map((item, index) => (
            <div style = {{display: 'flex', flexDirection:'row', justifyContent: 'space-between', paddingBottom:12, paddingTop:10,
                borderBottom: networkLength > 1 ? '1px solid #E7E9ED' : 'none',
            }}>
                    <div style = {{ display: 'flex', flexDirection: 'row', gap: 12, width: 120}}>
                        <span
                            style={{
                                display: 'inline-flex',
                                gap: '12px',
                                fontSize: 20,
                                alignItems: 'center',
                            }}
                            >
                            {(
                                <img
                                alt={item.coin}
                                src={item.coinUrl}
                                style={{ width: '40px', height: 'auto' }}
                            />)}
                            {item.coin.split('(')[0].toUpperCase()} 
                        </span>
                    </div>

                    <CurrencyBalance  key ="key1" available = {item.totalBalance} pending = {item.pendingBalance}/>
                    <CurrencyBalance key = "key2" available = {item.usdValue} pending = {item.pendingUSDValue}  isCrypto={false}/>
                   
                    <div style = {{ display: 'flex', justifyContent: 'flex-start',alignItems: 'flex-end', flexDirection: 'column', width:100}}>
                    <div className={`${item.totalBalance - item.pendingBalance < 0 ? styles.negative : ''}`} style={{ fontSize: 16}}>{item.pendingTx}</div>
                    </div>
            </div>
            ))}
            </div>
            </div>
            <div>
            {(networkItems.length > 1) &&
            <div style={{flex:2}}>
            <div style = {{display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginTop:20}}>
                    <div style = {{ display: 'flex', flexDirection: 'row', gap: 12, width: 120, fontSize:20}}>
                    {LIQUIDITY_MANAGEMENT_CONSTANTS.TOTALS}</div>
                    
                    <div style = {{ display: 'flex', justifyContent: 'center', alignItems:'right', flexDirection: 'column'}}>
                        <div className={styles.row}>
                                <div style={{ width: 250, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
        
                                </div>
                        </div>
                    </div>
                    <CurrencyBalance available = {totalUsdValue} pending = {totalPendingValue} isCrypto = {false}/>
                    <div style = {{ display: 'flex', justifyContent: 'flex-start',alignItems: 'flex-end', flexDirection: 'column', width:100}}>
                    <div className={`${totalUsdValue - totalPendingValue < 0 ? styles.negative : ''}`} style={{ fontSize: 16}}>{isNaN(totalPendingTxs) ? '' : totalPendingTxs}</div>
                    </div>
                </div>
             </div>
            } 
            </div>
        </div>
        
    </div>
    );
};

export default NetworkCard;