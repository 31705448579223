import React, { useState } from 'react';
import { Card, Space, Typography, Badge, Row, Col, Tag, Divider } from 'antd';
import { PlaySquareOutlined } from '@ant-design/icons';
import style from './style.module.scss';
import { useLocation } from 'react-router-dom';
import { checkEmptyString } from '../../utils/common.utils';
import { checkFalsyValues } from '../../utils/common.utils';

const MatchRule = ({ data }) => {
  const { Title } = Typography;
  const location = useLocation();
  const matchRules = data?.scoringResult?.matchedRules;

  return (
    <>
      {matchRules !== undefined && matchRules?.length !== 0 && (
        <section className={style.spaceMange}>
          <Title level={3}>Matched Rule {matchRules?.length}</Title>
          <Card>
            <Space
              wrap
              split={<Divider style={{ height: '3rem' }} type='vertical' />}
            >
              <span>
                score <br />
                <Badge
                  count={checkFalsyValues(
                    location.state?.sumsubResponse?.scoringResult?.score
                  )}
                  showZero
                  color='#faad14'
                  overflowCount={1000}
                />
              </span>
              {console.log(
                location.state?.sumsubResponse,
                'sumsubResponse'
              )}
              <span>
                Matched Rules <br />
                {0}
                {/* {checkFalsyValues(
                  location.state?.sumsubResponse?.scoringResult?.ruleCnt
                )} */}
              </span>
              <span>
                Considered Rules <br />
                {checkFalsyValues(
                  location.state?.sumsubResponse?.scoringResult?.ruleCnt
                )}
                {/* {checkFalsyValues(
                  location.state?.sumsubResponse?.scoringResult
                    ?.dryRunRuleCnt
                )} */}
              </span>
            </Space>
            <Row
              style={{ marginTop: '20px', height: '100%' }}
              gutter={[20, 20]}
            >
              {matchRules !== undefined &&
                matchRules?.length !== 0 &&
                matchRules?.map((item, index) => {
                  return (
                    <Col key={index}>
                      <Card
                        style={{
                          width: 350,
                          height: '100%',
                        }}
                      >
                        {item?.action !== 'reject' ? (
                          <div style={{ display: 'flex', height: '22px' }}>
                             {item?.stage === 'pre' && (
                            <Tag bordered={false} color='grey'>
                              <PlaySquareOutlined /> {'Pre-Scoring'}
                            </Tag>
                             )}
                            <Tag bordered={false} color='purple'>
                              Only Score
                            </Tag>
                            {item?.dryRun && (
                              <Tag bordered={false} color='yellow'>
                                Test mode
                              </Tag>
                            )}
                            {item?.score > 0 && <p>Score: {item?.score}</p>}
                          </div>
                        ) : (
                          <div style={{display:'flex',justifyContent:'space-between',height:'22px'}}>
                            <Tag bordered={false} color='red'>
                              {'Reject'}
                            </Tag>
                            <p>Score: {item?.score}</p>
                          </div>
                        )}
                        <p style={{ marginTop: '10px' }}>
                          {checkEmptyString(item?.title)}
                        </p>
                        {item?.tags !== undefined &&
                          item?.tags?.length !== 0 &&
                          item?.tags?.map((tag, index) => {
                            return (
                              <Tag bordered={false} color='red' key={index}>
                                {checkEmptyString(tag)}
                              </Tag>
                            );
                          })}
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Card>
        </section>
      )}
    </>
  );
};

export default MatchRule;
