import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { copyToClipboard } from '../utils/common.utils';

export const SIDEBAR_CONSTANTS = {
    REWARDS: "Rewards",
}

export const REWARDS_CONSTANTS = {
    REWARDS: "Rewards",
    REWARDS_ACTIVE: "Rewards Active",
    SEARCH_PLACEHOLDER: "Search by Risely ID, email",
    STATUS: "Status",
    UPDATED_FROM: "Updated from",
    UPDATED_TO: "Updated to",
    SORT_BY_STATUS: "Sort by Status",
    FILTER: "Filter",
    CSV: "CSV",
    RISELY_ID: "Risely ID (inviter)",
    NAME: "Name",
    REFERRAL_CODE: "Referral Code",
    SIGNED_UP: "Signed up #",
    VERIFIED: "Verified #",
    TOTAL_REWARD_EARNED: "Total Reward Earned",
    ACCOUNT_VERIFICATION_REWARDS: "Account Verification Rewards",
    TRANSACTION_REWARDS: "Transaction Rewards",
    REDEEMED_REWARDS: "Redeemed Rewards",
    REWARD_BALANCE: "Reward Balance",
    UPDATED: "Updated",
}

export const REWARDS_COLUMNS = (styles) => [
  {
    title: REWARDS_CONSTANTS.RISELY_ID,
    dataIndex: 'riselyId',
    key: '1',
    render: (text) => (
      <div>
        <span className={styles.underlinedText}>{text}</span>{' '}
        <Tooltip title="Copy">
          <CopyOutlined onClick={() => copyToClipboard(text)} className={styles.copyIcon} />
        </Tooltip>
      </div>
    ),
  },
  {
    title: REWARDS_CONSTANTS.NAME,
    dataIndex: 'name',
    key: '2',
  },
  {
    title: REWARDS_CONSTANTS.REFERRAL_CODE,
    dataIndex: 'referralCode',
    key: '3',
  },
  {
    title: REWARDS_CONSTANTS.SIGNED_UP,
    dataIndex: 'signedUp',
    key: '4',
    render: (text) => <span className={styles.underlinedText}>{text}</span>,
  },
  {
    title: REWARDS_CONSTANTS.VERIFIED,
    dataIndex: 'verified',
    key: '5',
    render: (text) => <span className={styles.underlinedText}>{text}</span>,
  },
  {
    title: REWARDS_CONSTANTS.TOTAL_REWARD_EARNED,
    dataIndex: 'totalRewardEarned',
    key: '6',
  },
  {
    title: REWARDS_CONSTANTS.ACCOUNT_VERIFICATION_REWARDS,
    dataIndex: 'accountVerificationRewards',
    key: '7',
  },
  {
    title: REWARDS_CONSTANTS.TRANSACTION_REWARDS,
    dataIndex: 'transactionRewards',
    key: '8',
  },
  {
    title: REWARDS_CONSTANTS.REDEEMED_REWARDS,
    dataIndex: 'redeemedRewards',
    key: '9',
  },
  {
    title: REWARDS_CONSTANTS.REWARD_BALANCE,
    dataIndex: 'rewardBalance',
    key: '10',
  },
  {
    title: REWARDS_CONSTANTS.UPDATED,
    dataIndex: 'updated',
    key: '11',
  },
];
