import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import TableFilterTittle from '../../Components/TableFilterTittle/TableFilterTittle.jsx';
import Crypto from '../UserDirectory/transations/Crypto.jsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLoading,
  finishLoading,
} from '../../redux/features/loading/loadingSlice';
import { Utils } from '../../utils/index.js';
import { WalletActions } from '../../redux/features/wallet/walletActions.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../../constants/index.js';
import { setHeaderAndRows } from '../../redux/features/download/downloadSlice.js';
import { TransactionFeeService } from '../../services/transaction-fee.service.js';
import { formatTxnType } from '../../services/transaction.service.js';

const CryptoTransaction = () => {
  const download = useSelector((state) => state.download);
  const { Text } = Typography;
  const [transactionData, setTransactionData] = useState({
    list: [],
    totalCount: 0,
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const formatTransactionData = (data) => {
    const { formatDateTime, checkEmptyString } = Utils.common;
    return data.map((obj, index) => {
      return {
        key: index + 1,
        created: formatDateTime(obj.createdAt),
        amount: obj.amount,
        currency: obj.coin,
        txnId: checkEmptyString(obj.typeId),
        adminApproval: obj.adminApproval,
        type: obj.type,
        from: obj.fromAddress,
        fee: obj.fee,
        to: obj.toAddress,
        id: obj.typeId,
        clientId: obj.clientId,
        status: obj.status,
        isCrypto: obj.isCrypto,
        approved_by: obj.approvedBy || null,
        kytStatus: obj.kytStatus,
      };
    });
  };

  const formatDownloadData = (data) => {
    if (data && data.length > 0) {
      return data.map((obj) => {
        return {
          ...obj,
          amount: TransactionFeeService.getFeeAmountAfterPrecision(
            obj.currency,
            obj.amount
          ),
          type: formatTxnType(obj.type),
        };
      });
    }
    return [];
  };

  const getCryptoTransactions = async (data) => {
    try {
      const updatedState = { ...location.state, ...data }; // Add or update state
      navigate('/crypto-transaction', { state: { ...updatedState } }); // Navigate to the same path with new state

      await dispatch(startLoading());
      const res = await dispatch(
        WalletActions.getCryptoTransactions(data)
      ).unwrap();
      if (res && res.totalCount > 0) {
        const txnData = formatTransactionData(res.list);
        const downloadData = formatDownloadData(txnData);
        if (!download.isEnabled && download.path === 'NONE') {
          setTransactionData({ list: txnData, totalCount: res.totalCount });
        } else {
          dispatch(
            setHeaderAndRows({
              headers: CONSTANTS.CRYPTO_CSV_HEADERS,
              rows: downloadData,
            })
          );
        }
      } else {
        setTransactionData({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const obj = { ...location.state };
  useEffect(() => {
    const data = {
      clientId: '',
      coin: '',
      page: 1,
      size: 10,
      txStatus: 'ALL',
      type: 'receive,send,internal-deposit,internal-withdraw',
    };
    getCryptoTransactions(data);
  }, []);

  return (
    <>
      <div className='table-designa'>
        <TableFilterTittle title='Crypto Transactions' />
        <Crypto
          data={transactionData.list}
          totalCount={transactionData.totalCount}
          getCryptoTransactions={getCryptoTransactions}
        />
      </div>
    </>
  );
};

export default CryptoTransaction;
