import React, { useState } from 'react';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';
import CustomCheckbox from '../../../Components/CustomCheckbox/CustomCheckbox';
import { Table } from 'antd';
import {
  formatDateTime,
  formatFiatValues,
  handleHideId,
} from '../../../utils/common.utils';
import CopyToClipboard from '../../../Components/CopyToClipboard/CopyToClipboard';
import { useDispatch } from 'react-redux';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import { WalletActions } from '../../../redux/features/wallet/walletActions';
import { formatTxnType } from '../../../services/transaction.service';
import { TransactionFeeService } from '../../../services/transaction-fee.service';

function CryptoTransactions({ getKytTxn, kytDetails }) {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    setIsCheckedAll(isChecked);

    if (isChecked) {
      // Select all rows, keeping consistent structure
      setSelectedRows(
        kytDetails.list.map((record) => ({
          typeId: record.typeId,
          coin: record.coin,
          clientId: record.clientId,
          createdAt:new Date(record.createdAt).getTime()
        }))
      );
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const handleRowCheckboxChange = (record, isChecked) => {
    setSelectedRows((prev) => {
      if (isChecked) {
        // Add record to selected rows
        return [
          ...prev,
          {
            typeId: record.typeId,
            coin: record.coin,
            clientId: record.clientId,
            createdAt:new Date(record.createdAt).getTime()
          },
        ];
      } else {
        // Remove record from selected rows
        return prev.filter((item) => item.typeId !== record.typeId);
      }
    });

    setIsCheckedAll(
      isChecked
        ? selectedRows.length + 1 === kytDetails.list.length
        : selectedRows.length - 1 === kytDetails.list.length
    );
  };

  const onApproveTxn = async () => {
    let data = {
      page: 1,
      size: 10,
      txStatus: 'PENDING',
      coinName: '',
      trxnType: 'CRYPTO',
      type: 'all',
      isLowBalanceStatus:1,
    };
    try {
      await dispatch(startLoading());
      const res = await dispatch(
        WalletActions.updateInReviewTransactions({
          inReviewTransactions: selectedRows,
          status: 'APPROVED',
        })
      ).unwrap();
      if (res) {
        getKytTxn(data);
        setShowModal(false);
        setSelectedRows([]);
        setIsCheckedAll(false);
      }
    } catch (error) {
      await dispatch(finishLoading());
      console.log('ERROR::', error);
    }
  };
console.log("selectedRows::",selectedRows);
  const columns = [
    {
      title: (
        <div>
          <CustomCheckbox checked={isCheckedAll} onChange={handleCheckAll} />
        </div>
      ),
      render: (_, record) => {
        const isChecked = selectedRows.some(
          (item) => item.typeId === record.typeId
        );
        return (
          <CustomCheckbox
            checked={isChecked}
            onChange={(e) => handleRowCheckboxChange(record, e.target.checked)}
          />
        );
      },
    },
    {
      title: 'Txn Id',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        return (
          <>
            {record?.id ? (
              <>
                <span
                  style={{ cursor: 'pointer' }}
                  // onClick={() => getTransactionDetails(record)}
                >
                  {handleHideId(record?.id)}
                </span>
                <CopyToClipboard userId={record?.id} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Client Id',
      dataIndex: 'clientId',
      key: 'clientId',
      render: (_, record) => {
        return (
          <>
            {record?.clientId ? (
              <>
                <span>{handleHideId(record?.clientId)}</span>
                <CopyToClipboard userId={record?.clientId} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Currency',
      dataIndex: 'coin',
      key: 'coin',
      render: (_, record) => {
        return typeof record.coin === 'string'
          ? record.coin.toUpperCase()
          : '-';
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => {
        let amount = TransactionFeeService.getFeeAmountAfterPrecision(
          record.coin,
          record.amount
        );

        return record.isCrypto === 1 ? (
          TransactionFeeService.checkIsNumberExponential(amount)
        ) : (
          <>
            <p>{formatFiatValues(Number(record?.amount))}</p>
          </>
        );
      },
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => {
        return (
          <>
              {formatTxnType(record?.type) === 'SWAP-IN' ||
              formatTxnType(record?.type) === 'SWAP-OUT'
                ? 'SWAP'
                : formatTxnType(record?.type)}
          </>
        );
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => formatDateTime(record.createdAt),
    },
  ];

  return (
    <div className='table-design'>
      <div className='title-filter' style={{ float: 'right' }}>
        <ButtonCommon
          className='filter-btn'
          reject='approve'
          disable={selectedRows.length == 0}
          onClick={() => setShowModal(true)}
        >
          <span style={{ color: 'black' }}>Approve</span>
        </ButtonCommon>
      </div>
      <div className='custom-table'>
        <Table
          dataSource={kytDetails?.list}
          columns={columns}
          pagination={false}
          rowKey='txnId'
        />
      </div>
      <CustomModal
        title={`Are you sure, you want to approve?`}
        open={showModal}
        onOk={onApproveTxn}
        onCancel={() => setShowModal(false)}
      ></CustomModal>
    </div>
  );
}

export default CryptoTransactions;