import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  finishLoading,
  startLoading,
} from '../../../redux/features/loading/loadingSlice';
import CryptoTransactions from './CryptoTransactions';
import { WalletActions } from '../../../redux/features/wallet/walletActions';
import TableFilterTittle from '../../../Components/TableFilterTittle/TableFilterTittle';
const InReviewTabs = () => {
  const dispatch = useDispatch();
  const [kytDetails, setKytDetails] = useState({
    list: [],
    totalCount: '',
  });
  const handleKytTxnRes = (res) => {
    if (res && ((res.list && res.list.length > 0) || +res.totalCount > 0)) {
      const updatedRes = res.list.map((item) => {
        return {
          ...item,
        };
      });

      setKytDetails({
        list: updatedRes,
        totalCount: res.totalCount,
      });
    } else if (res && (res.list?.length === 0 || +res.totalCount === 0)) {
      setKytDetails({
        list: [],
        totalCount: 0,
      });
    }
  };
  const getKytTxn = async (data) => {
    try {
      await dispatch(startLoading());
      const res = await dispatch(
        WalletActions.getAllTransactions(data)
      ).unwrap();
      handleKytTxnRes(res);
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(finishLoading());
    }
  };

  useEffect(() => {
    const data = {
      page: 1,
      size: 10,
      txStatus: 'PENDING',
      coinName: '',
      trxnType: 'CRYPTO',
      type: 'all',
      isLowBalanceStatus: 1,
    };
    getKytTxn(data);
  }, []);

  return (
    <>
      <div className='Custom-tabBtn'>
        <TableFilterTittle title='Insufficient Liquidity Transactions' />
        <CryptoTransactions getKytTxn={getKytTxn} kytDetails={kytDetails} />
      </div>
    </>
  );
};

export default InReviewTabs;