import InfoCard from "../../../Components/Card/InfoCard";
import styles from "./style.module.scss"; 
import { CONSTANTS } from "../../../constants";
import CurrencyBalance from "./CurrencyBalance";

const FiatItem = ({ fiatItems }) => {
    console.log("fiatItems from NetworkCard:", fiatItems);
    const { LIQUIDITY_MANAGEMENT_CONSTANTS } = CONSTANTS;

    // Calculate total balance and total USD value
    const totalPendingValue = fiatItems.reduce((acc, item) => acc + (item.pendingBalance || 0), 0);
    const totalUsdValue = fiatItems.reduce((acc, item) => acc + item.usdValue, 0);
    const myValue = '- ';

    const networkLength = fiatItems.length;

    return(
        <div className={styles.labelInABoxShadow} style={{marginTop:24}}>
        <div style={{display: 'flex', flexDirection:'row', width: '100%'}}>
           <div style={{flex:1}}>
                <div style = {{display: 'flex', flexDirection:'row', alignContent:'right'}}>
                    <div style = {{display: 'flex', flexDirection: 'column', gap:12}}>
                        <div style={{fontSize:24}}>{LIQUIDITY_MANAGEMENT_CONSTANTS.NARVI}</div>
                        <InfoCard key="key" title="Wallet / Account Number:" detail= {(fiatItems[0]?.number || '').toLocaleString()}  isCopy = "true"/>
                    </div>
                </div>  
           </div>

            <div style={{flex:2}}>
                <div style = {{display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginTop:20}}>
                    <div style = {{ display: 'flex', flexDirection: 'row', gap: 12, width: 120}}></div>
                    {['Currency Balance', 'Currency Balance, $'].map((text, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <div className={styles.row}>
                                <div style={{ width: 250, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                    <div style={{ fontSize: 12, textAlign: 'right', paddingBottom: 12 }}>{text}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div style = {{ display: 'flex', justifyContent: 'flex-start',alignItems: 'flex-end', flexDirection: 'column', width:100}}>
                        <div style={{fontSize: 12, paddingBottom:12}}>Pending Tx's</div>
                    </div>
                </div>
            <div>
                
            {/* Network Items Section */}
            {fiatItems.map((item, index) => (
            <div  key = {item.currency} style = {{display: 'flex', flexDirection:'row', justifyContent: 'space-between', paddingBottom:12, paddingTop:10,
                borderBottom: networkLength > 1 ? '1px solid #E7E9ED' : 'none',
            }}>
                    <div style = {{ display: 'flex', flexDirection: 'row', gap: 12, width: 120}}>
                        <span
                            style={{
                                display: 'inline-flex',
                                gap: '12px',
                                fontSize: 20,
                                alignItems: 'center',
                            }}
                            >
                            {(
                                <img
                                alt={item.coin}
                                src={`${CONSTANTS.ENV.KYC_DOCS_URL}/stage-fcbank/all_icons/${item.currency?.toLowerCase()}.png`}
                                style={{ width: '40px', height: 'auto' }}
                            />)}
                            {item.currency} 
                        </span>
                    </div>

                    <CurrencyBalance  key ="key1" available = {item.balance} pending = {0}  isCrypto={false}/>
                    <CurrencyBalance key = "key2" available = {item.usdValue} pending = {0}  isCrypto={false}/>
                   
                    <div style = {{ display: 'flex', justifyContent: 'flex-start',alignItems: 'flex-end', flexDirection: 'column', width:100}}>
                    <div className={`${(item.balance - 0 ) < 0 ? styles.negative : ''}`} style={{ fontSize: 16}}>{myValue}</div>
                    </div>
            </div>
            ))}
            </div>
            <div>
            {(fiatItems.length > 1) &&
            <div style={{flex:2}}>
            <div style = {{display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginTop:20}}>
                    <div style = {{ display: 'flex', flexDirection: 'row', gap: 12, width: 120, fontSize:20}}>
                    Totals</div>
                    
                    <div style = {{ display: 'flex', justifyContent: 'center', alignItems:'right', flexDirection: 'column'}}>
                        <div className={styles.row}>
                                <div style={{ width: 250, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
        
                                </div>
                        </div>
                    </div>
                    <CurrencyBalance  key ="key1" available = {totalUsdValue} pending = {totalPendingValue}/>
                    <div style = {{ display: 'flex', justifyContent: 'flex-start',alignItems: 'flex-end', flexDirection: 'column', width:100}}>
                    <div className={`${myValue < 0 ? styles.negative : ''}`} style={{ fontSize: 16}}>{myValue}</div>
                    </div>
                </div>
             </div>
            } 
            </div>
            </div>
        </div>
    </div>
    );
};

export default FiatItem;